import React, {useState, FormEvent, useContext} from "react"
import {useMutation} from "@apollo/react-hooks"
import {Search} from "../Tmdb/Search"

import MUTATION_COLLECTION_ADD from "graphql/Media/Collection/mutation/collectionAdd"
import {alertContext} from "context/Alert/alert"

export const CollectionAdd = (props: any) => {


  const [mutate] = useMutation(MUTATION_COLLECTION_ADD)

  const {setMessage, setError} = useContext(alertContext)

  const [collectionName, updateCollectionName] = useState("")
  const [collectionTmdbOptions, updateTmdbOptions] = useState(null)

  const onChange = (name: string, tmdbOptions: any) => {
    updateCollectionName(name)
    updateTmdbOptions(tmdbOptions)
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    mutate({
      variables: {
        input: {
          name: collectionName,
          tmdbOptions: collectionTmdbOptions
        }
      }
    })
      .then(() => {
        setMessage(<h1>OK :)</h1>)
      })
      .catch(() => {
        setError(<h1>Oups :)</h1>)
      })
  }

  return <div>
    <h1>Collection</h1>
    {collectionName && <h2>{collectionName}</h2>}
    <form onSubmit={onSubmit}>
      <div className="col-sm-6">
        <div className="input-group">
          <Search
            type='collection'
            onChange={onChange}
          />
        </div>
        <button className="btn btn-dark">Enregister</button>
      </div>
    </form>
  </div>
}