import gql from "graphql-tag";

export default gql`

  fragment SessionLatestFragment on Session {
    id
    progress
    lastUpdate
    VersionSelected {
      id
    }
    SubtitleSelected {
      id
    }
    Media {
      id
      duration
      Episode {
        id
        name
        number
        Season {
          id
          number
          Show {
            id
            name
            tmdbOptions
          }
        }
      }
      Movie {
        id
        name
        tmdbOptions
      }
    }
  }

`