import React, {useRef} from "react";
import {GoogleLogin} from "react-google-login"
import {useMutation, useQuery} from "@apollo/react-hooks";

import InputGroup from "react-bootstrap/InputGroup"
import FormControl from "react-bootstrap/FormControl"

import {saveLoginToken} from "helpers/User";
import userLoginByGoogleToken from "graphql/User/mutation/userLoginByGoogleToken";
import userProfileQuery from "graphql/User/query/userProfile";

import './style.scss'

export const LoginForm = (props: any) => {

  const [mutate] = useMutation(userLoginByGoogleToken)
  const {refetch} = useQuery(userProfileQuery)

  const handleLogin = async (tokenId: string) => {
    const response = await mutate({
      variables: {
        token: tokenId,
      }
    })

    const {loginToken} = response?.data || {}
    const {token, expiration} = loginToken || {};

    saveLoginToken(token, expiration)
    refetch()
  }

  const handleGoogleLogin = async (payload: any) => {
    const {tokenId} = payload
    if (tokenId) {
      handleLogin(tokenId)
    }
  }


  const emailRef = useRef(null)
  const passwordRef = useRef(null)

  const _onSubmit = (event: any) => {
    event.preventDefault();
    const {onLogin, onLoginSuccess} = props
    onLogin && onLogin({
      email: emailRef.current.value,
      password: passwordRef.current.value,
    })
      .then(() => {
        onLoginSuccess && onLoginSuccess()
      })
  }

  return <div className='login-container'>

    <h1>Connexion</h1>
    <div className="row">
      <div className="col-6">
        <form onSubmit={_onSubmit}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>email</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              ref={emailRef}
              name="email"
              placeholder="email"
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Mot de passe</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              className="form-control"
              ref={passwordRef}
              name="password"
              placeholder="password"
              type="password"
            />
          </InputGroup>
          <button className="btn btn-dark" type="submit">Entrer</button>
        </form>
      </div>
      <div className="col-6">
        <div>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID}
            buttonText="Login"
            onAutoLoadFinished={(autoload) => console.log({autoload})}
            onSuccess={handleGoogleLogin}
            onFailure={(error: any) => console.log({error})}
            cookiePolicy={'single_host_origin'}
            isSignedIn={true}
          // autoLoad={true}
          />
        </div>
      </div>
    </div>
  </div>

}
