import gql from "graphql-tag";

import FileFragment from "../../File/fragment/FileFragment"

export default gql`

  ${FileFragment}

  subscription FileUpdate($id: String!) {
    fileUpdate(id: $id) {
      ...FileFragment
    }
  }

`
