import React from 'react';

import {Router} from "./routes"

import "static/css/common.scss"
import "static/css/icons.scss"

const App = () => {

  return <div className="app-container">
    <Router />
  </div>

}

export default App;
