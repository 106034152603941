import React from "react"
import {EpisodeEdit} from "components/backoffice/Media/Episode/EpisodeEdit"

export const EpisodeEditRoute = (props: any) => {

  const {match} = props
  const {params} = match
  const {id} = params

  return <div>
    <h1>{id}</h1>
    <EpisodeEdit id={id} />
  </div>
}