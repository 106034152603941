import React, {useState} from "react";
import {useQuery, useMutation} from "@apollo/react-hooks";

import {Player} from "../../../Player/Player";

import mediaGet from "graphql/Media/Media/query/mediaGet";
import sessionAdd from "graphql/Streaming/Session/mutation/sessionAdd";
import sessionUpdateMutation from "graphql/Streaming/Session/mutation/sessionUpdate";
import {MediaWatchNext} from "../WatchNext/MediaWatchNext";
import mediaWatchNext from "graphql/Media/Media/query/mediaWatchNext";
import {useHistory} from "react-router-dom";
import {URL_FRONT_EPISODE_PLAY, URL_FRONT_MOVIE_PLAY} from "static/constant/url";


type MediaPlayProps = {
  id: string
  // versionId: any
}

export const MediaPlay = (props: MediaPlayProps) => {

  const history = useHistory()

  const mediaId = props?.id

  const watchNextQuery = useQuery(mediaWatchNext, {
    skip: !mediaId,
    variables: {
      id: mediaId,
    }
  })

  const [session, sessionUpdate] = useState(null)

  const [mutate] = useMutation(sessionAdd)
  const [updateSessionAPI] = useMutation(sessionUpdateMutation)

  const {data} = useQuery(mediaGet, {
    skip: !mediaId,
    variables: {
      id: mediaId,
    },
    fetchPolicy: "no-cache", // Important because of the main route sessionlist
    onCompleted: async (data) => {
      if (mediaId) {

        const media = data?.media
        const hasSession = !!(media?.Session?.length > 0)

        if (hasSession) {
          console.log(`Has already a session`)
          const lastSession = hasSession && media?.Session[0]
          sessionUpdate(lastSession)
        } else {
          console.log(`No session, creating one with media id ${media?.id}`)
          if (media?.id) {

            const {data} = await mutate({
              variables: {
                input: {
                  progress: 0,
                  Media: media?.id,
                  VersionSelected: media?.Version[0]?.id,
                }
              }
            })
            sessionUpdate(data?.session)
          }
        }
      }
    }
  })

  const updateVersion = async (versionId: string) => {

    console.log(`Updating version with version id ${versionId}`)
    const media = data?.media
    const lastSession = media?.Session[0]

    const {id, lastUpdate, sessionGroup, __typename, ...sessionInput} = lastSession

    const sessionUpdated = {
      ...sessionInput,
      Media: media?.id,
      VersionSelected: versionId
    }

    const response = await updateSessionAPI({
      variables: {
        id: lastSession?.id,
        input: {
          ...sessionUpdated
        }
      }
    })
    sessionUpdate(response?.data?.session)
}

  const onMediaEnd = () => {
    const media = watchNextQuery.data?.media
    if (media) {
      const isEpisode = !!media?.Episode
      if (isEpisode) {
        history.push(URL_FRONT_EPISODE_PLAY.replace(':id', media?.Episode?.id))
      } else {
        history.push(URL_FRONT_MOVIE_PLAY.replace(':id', media?.Movie?.id))
      }
    }
  }

  const versionList = data?.media?.Version
  const versionSelected = session?.VersionSelected?.id
  const hasManyVersion = versionList?.length > 1

  return (
    <div className="media-container">
      {hasManyVersion && <div>
        <select name="version" className="form-control" value={versionSelected} onChange={e => updateVersion(e.target.value)}>
          {versionList?.map((version: any) => {
            return <option key={version?.id} value={version?.id}>{version?.language}</option>
          })}
        </select>
      </div>}
      {(session && true) && <Player session={session} onEnd={onMediaEnd} />}
      <MediaWatchNext media={watchNextQuery?.data?.media} />
    </div>
  );

}