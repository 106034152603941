import gql from "graphql-tag";

export default gql`
  fragment ImportFragment on Import {
    id
    File {
      id
      name
      transferProgress
    }
    hasError
    isRunning
    status
  }
`