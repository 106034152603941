import gql from "graphql-tag";

import showFragment from "../fragment/showFragment"

export default gql`

	${showFragment}

	query showGet($id: String!) {
		show: showGet(id : $id) {
			...showFragment
		}
	}
`