import React, {useState, useEffect, useContext} from "react"
import {useQuery, useMutation} from "@apollo/react-hooks"

import userGet from "graphql/User/query/userGet"
import userAdd from "graphql/User/mutation/userAdd"

import {alertContext} from "context/Alert/alert"

import './style.scss'

type UserDetailsProps = {
  userId: string,
  open?: boolean,
}

export const UserDetails = (props: UserDetailsProps) => {

  const {setMessage, setError} = useContext(alertContext)
  const userAddMutation = useMutation(userAdd)
  const {data} = useQuery(userGet, {
    variables: {
      id: props?.userId
    },
    skip: !props?.userId
  })

  const [open, updateOpen] = useState<boolean>(props?.open ?? false)
  const [formUpdated, updateFormUpdated] = useState<boolean>(false)
  const [user, updateUser] = useState<any>({})

  useEffect(() => {
    updateUser(data?.user)
  }, [data])

  const onChange = (user: any) => {
    updateFormUpdated(true)
    updateUser(user)
  }

  const saveForm = () => {
    if (data?.user?.id) {
      console.log("DOING NOTHING FOR UPDATE")
    } else {
      userAddMutation[0]({
        variables: {
          input: {
            email: user?.email,
          }
        }
      })
      .then(() => setMessage(<h1>OK</h1>))
      .catch(() => setError(<h1>Oups</h1>))
    }
  }

  const resetForm = () => {
    updateFormUpdated(false)
    updateUser(data?.user)
  }

  return <div className="user-details-container">
    <div className="card">
      <div className="card-header" onClick={() => updateOpen(!open)}>
        {user?.email}
      </div>
      <div className={open ? "collapse-container collapse-visible" : "collapse-container"}>
        <div className="card-body">
          <div className="input-group">
            <span className="input-group-text">Email</span>
            <input className="form-control" type="text" value={user?.email || ""} placeholder="Email" onChange={(e) => {
              onChange({
                ...user,
                email: e.target.value,
              })
            }} />
          </div>
          {formUpdated && <div className="actions">
            <button className="btn btn-dark" type="button" onClick={() => saveForm()}>Enregister</button>
            <button className="btn btn-danger" type="button" onClick={() => resetForm()}>Annuler</button>
          </div>}
        </div>
      </div>
    </div>
  </div>
}