import gql from "graphql-tag";

export default gql`
  fragment FileFragment on File {
    id
    localStatus
    remoteStatus
    transferProgress
  }

`