import React, {useContext, useRef} from "react"
import {useSubscription, useMutation} from "@apollo/react-hooks"
import providerScanLog from "graphql/Storage/Provider/subscription/providerScanLog"
import providerScan from "graphql/Storage/Provider/mutation/providerScan"
import {alertContext} from "context/Alert/alert"

import "./style.scss"


type ProviderScanProps = {

}


export const ProviderScan = (props: ProviderScanProps) => {
  const {setError, setMessage} = useContext(alertContext)
  // const [loading, updateLoading] = useState(false)

  const loggerRer = useRef(null)

  useSubscription(providerScanLog, {
    onSubscriptionData: (response) => {
      // @ts-ignore
      const {log} = response.subscriptionData.data?.providerScan
      // console.log({status})
      // updateLoading(!!(status === "RUNNING"))
      appendLog(log)
    }
  })

  const appendLog = (log: string) => {
    const div = document.createElement('div')
    div.textContent = log;
    loggerRer.current && loggerRer.current.prepend(div)
  }

  const [mutate, {loading}] = useMutation(providerScan)

  const startScan = () => {
    mutate()
      .then(() => setMessage(<h1>Done :)</h1>))
      .catch(() => setError(<h1>Oups :(</h1>))
  }

  return <div className="provider-scan-container">
    <h1>Scanner les Provider</h1>
    <div className="actions">
      <button className="btn btn-dark" type="button" disabled={loading} onClick={startScan}>{loading && <span className="icon-loading"></span>} Lancer le scan</button>
    </div>
    <div className="logger" ref={loggerRer}>
    </div>
  </div>
}