import gql from "graphql-tag"
import SessionFragment from "graphql/Streaming/Session/fragment/SessionFragment"

export default gql`

  ${SessionFragment}

  fragment MediaFragment on Media {
    id
    duration
    Version {
      id
      language
    }
    Session {
      ...SessionFragment
    }
  }
`