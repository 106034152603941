import React from "react"
import {Switch, Route} from "react-router-dom"

import {MainRoute} from "./Main/MainRoute"
import {ShowPlayRoute} from "./Media/Show/ShowPlayRoute"
import {EpisodePlayRoute} from "./Media/Episode/EpisodePlayRoute"
import {URL_FRONT, URL_FRONT_SHOW_PLAY, URL_FRONT_EPISODE_PLAY, URL_FRONT_COLLECTION_PLAY, URL_FRONT_MOVIE_PLAY, URL_FRONT_SEARCH} from "static/constant/url"
// import {Menu} from "components/front/Navigation/Menu"
import {CollectionPlayRoute} from "./Media/Collection/CollectionPlayRoute"
import {MoviePlayRoute} from "./Media/Movie/MoviePlayRoute"
import {Alert} from "components/common/Navigation/Alert/Alert"
import {SearchRoute} from "./Search/SearchRoute"

// import "./style.scss"

export const front = (props: any) => {

  return <div className="front-container">
    <Alert />
    <div className="container">
      <Switch>
        <Route path={URL_FRONT_SHOW_PLAY} component={ShowPlayRoute} />
        <Route path={URL_FRONT_EPISODE_PLAY} component={EpisodePlayRoute} />
        <Route path={URL_FRONT_COLLECTION_PLAY} component={CollectionPlayRoute} />
        <Route path={URL_FRONT_MOVIE_PLAY} component={MoviePlayRoute} />
        <Route path={URL_FRONT_SEARCH} component={SearchRoute} />
        <Route path={URL_FRONT} component={MainRoute} />
      </Switch>
    </div>
  </div>
}