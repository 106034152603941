
import React, {useState} from "react"
import {useHistory, Link} from "react-router-dom"

import {URL_FRONT_SHOW_PLAY, URL_FRONT_COLLECTION_PLAY, URL_FRONT_EPISODE_PLAY, URL_FRONT_MOVIE_PLAY} from "static/constant/url"
// import {ShowType} from "../../../../../../common/model/Media/Show"

import "./style.scss"
import ProgressBar from "react-bootstrap/ProgressBar"
import {TmdbImageSizeEnum, getImageUrlFromSizeAndPath} from "helpers/Tmdb"

type SliderProps = {
  elementList: any[],
  type: MediaTypeEnum,
}

export enum MediaTypeEnum {
  SHOW = "SHOW",
  MOVIE = "MOVIE",
  COLLECTION = "COLLECTION",
  SESSION = "SESSION",
  EPISODE = "EPISODE",
}

type ShowItemProps = {
  show: any,
}

export const ShowItem = (props: ShowItemProps) => {

  const history = useHistory()
  const [showControls, updateShowControls] = useState(false)
  const {show} = props

  const onClick = () => {
    history?.push(URL_FRONT_SHOW_PLAY.replace(':id', show?.id))
  }

  const posterUrl = getImageUrlFromSizeAndPath(TmdbImageSizeEnum.W185, show?.tmdbOptions?.poster_path)
  return <div className="slide-item" onMouseEnter={() => updateShowControls(true)} onMouseLeave={() => updateShowControls(false)}>
    <div className={showControls ? "poster controls" : "poster"} style={{background: `url(${posterUrl})`}} onClick={onClick}>
      <div className="controls-content">
        <span className="icon-arrow" />
      </div>
    </div>
    <div className="title">
      {show?.name}
    </div>
  </div >
}

type EpisodeItemProps = {
  episode: any,
}

export const EpisodeItem = (props: EpisodeItemProps) => {

  const history = useHistory()
  const [showControls, updateShowControls] = useState(false)
  const {episode} = props

  const isViewable = episode?.isViewable

  const onClick = () => {
    if (isViewable) {
      history?.push(URL_FRONT_EPISODE_PLAY.replace(':id', episode?.id))
    }
  }

  const posterUrl = getImageUrlFromSizeAndPath(TmdbImageSizeEnum.W185, episode?.tmdbOptions?.still_path)
  return <div className="slide-item" onMouseEnter={() => updateShowControls(true)} onMouseLeave={() => updateShowControls(false)}>
    <div className={showControls ? "poster controls" : "poster"} style={{background: `url(${posterUrl})`}} onClick={onClick}>
      <div className="controls-content">
        {isViewable && <span className="icon-arrow" />}
        {!isViewable && <span className="badge badge-warning badge-light">Bientôt dispo</span>}
      </div>
    </div>
    <div className="title">
      <Link to={URL_FRONT_SHOW_PLAY.replace(':id', episode?.Season?.Show?.id)}><h1>{episode?.Season?.Show?.name}</h1></Link>
      <h2>{episode?.name}</h2>
      <h3>Saison {episode?.Season?.number} / Episode {episode?.number}</h3>
    </div>
  </div >
}

export const CollectionItem = (props: any) => {

  const history = useHistory()
  const [showControls, updateShowControls] = useState(false)
  const {collection} = props

  const onClick = () => {
    history?.push(URL_FRONT_COLLECTION_PLAY.replace(':id', collection?.id))
  }

  const posterUrl = getImageUrlFromSizeAndPath(TmdbImageSizeEnum.W185, collection?.tmdbOptions?.poster_path)

  return <div className="slide-item" onMouseEnter={() => updateShowControls(true)} onMouseLeave={() => updateShowControls(false)}>
    <div className={showControls ? "poster controls" : "poster"} style={{background: `url(${posterUrl})`}} onClick={onClick} >
      <div className="controls-content">
        <span className="icon-arrow" />
      </div>
    </div>
    <div className="title">
      {collection?.name}
    </div>
  </div >
}

export const MovieItem = (props: any) => {

  const history = useHistory()
  const [showControls, updateShowControls] = useState(false)
  const {movie} = props
  const {isViewable} = movie

  const onClick = () => {
    if (isViewable) {
      history?.push(URL_FRONT_MOVIE_PLAY.replace(':id', movie?.id))
    }
  }

  const posterUrl = getImageUrlFromSizeAndPath(TmdbImageSizeEnum.W185, movie?.tmdbOptions?.poster_path)

  return <div className="slide-item" onMouseEnter={() => updateShowControls(true)} onMouseLeave={() => updateShowControls(false)}>
    <div className={showControls ? "poster controls" : "poster"} style={{background: `url(${posterUrl})`}} onClick={onClick} >
      <div className="controls-content">
        {isViewable && <span className="icon-play" />}
        {!isViewable && <span className="badge badge-warning badge-light">Bientôt dispo</span>}
      </div>
    </div>
    <div className="title">
      {movie?.name}
    </div>
  </div >
}

export const SessionItem = (props: any) => {

  const history = useHistory()

  const [showControls, updateShowControls] = useState(false)
  const {session} = props

  const onEpisodeClick = () => {
    history?.push(URL_FRONT_EPISODE_PLAY.replace(':id', session?.Media?.Episode?.id))
  }

  const onMovieClick = () => {
    history?.push(URL_FRONT_MOVIE_PLAY.replace(':id', session?.Media?.Movie?.id))
  }

  const isMovie = !!session?.Media?.Movie

  const label = isMovie ? `${session?.Media?.Movie?.name}` : `Saison ${session?.Media?.Episode?.Season?.number} - Episode ${session?.Media?.Episode?.number} - ${session?.Media?.Episode?.name}`
  const percent = Math.floor(Number(Number(session?.progress) * 100 / Number(session?.Media?.duration)))

  const imageElement = session?.Media?.Episode?.Season?.Show?.tmdbOptions || session?.Media?.Movie?.tmdbOptions

  const posterUrl = getImageUrlFromSizeAndPath(TmdbImageSizeEnum.W185, imageElement.poster_path)

  return <div className="slide-item" onMouseEnter={() => updateShowControls(true)} onMouseLeave={() => updateShowControls(false)}>
    <div onClick={isMovie ? onMovieClick : onEpisodeClick} >
      <div className={showControls ? "poster controls" : "poster"} style={{background: `url(${posterUrl})`}}>
        <div className="controls-content">
          <span className="icon-play" />
        </div>
      </div>
      <div className="progress-session">
        <ProgressBar now={percent} />
      </div>
      <div className="title">
        {label}
      </div>
    </div>
  </div >
}

export const MediaSlider = (props: SliderProps) => {

  const {elementList, type} = props

  const _renderElementList = (list: any) => {
    return list?.map((el: any, index: Number) => {
      switch (type) {
        case MediaTypeEnum.SHOW: {
          return <div key={`element_${el.id}`}>
            <ShowItem show={el} />
          </div>
        }
        case MediaTypeEnum.COLLECTION: {
          return <div key={`element_${el.id}`}>
            <CollectionItem collection={el} />
          </div>
        }
        case MediaTypeEnum.SESSION: {
          return <div key={`element_${el.id}`}>
            <SessionItem session={el} />
          </div>
        }
        case MediaTypeEnum.MOVIE: {
          return <div key={`element_${el.id}`}>
            <MovieItem movie={el} />
          </div>
        }
        case MediaTypeEnum.EPISODE: {
          return <div key={`element_${el.id}`}>
            <EpisodeItem episode={el} />
          </div>
        }
        default: {
          return <div key={`element_${el.id}`}>
            {el?.id}
          </div>
        }
      }
    })
  }


  return <div className="slider-container">
    <div className="slider-row">
      {_renderElementList(elementList)}
    </div>
  </div>
}