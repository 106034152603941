import gql from "graphql-tag"
import ImportFragment from "../fragment/ImportFragment"

export default gql`

  ${ImportFragment}

  query importList {
    importList {
      ...ImportFragment
    }
  }
`