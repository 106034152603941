import React from "react"
import {useQuery} from "@apollo/react-hooks"
import providerListQuery from "graphql/Storage/Provider/query/providerList"
import {ProviderEdit} from "../Edit/ProviderEdit"

import "./style.scss"

export const ProviderList = () => {

  const {data} = useQuery(providerListQuery)
  const providerList = data?.providerList

  return <div className="provider-list-container">
    <h1>Provider List</h1>
    {providerList?.map((provider: any) => {
      return <ProviderEdit key={`provider_${provider?.id}`} id={provider?.id} />
    })}
  </div>
}