import React, {useState} from "react"

import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import {useAccordionToggle} from "react-bootstrap/AccordionToggle"
import {Link} from "react-router-dom"
import ProgressBar from "react-bootstrap/ProgressBar"

import {URL_FRONT_EPISODE_PLAY} from "static/constant/url"

import './style.scss'

import {SeasonStatus} from "../Status/SeasonStatus"
import seasonGet from "graphql/Media/Season/query/seasonGet"
import {useQuery} from "@apollo/react-hooks"
import {EpisodeUpdate} from "../../Episode/Update/EpisodeUpdate"

type SeasonPlayProps = {
  seasonId: string,
}

export const SeasonPlay = (props: SeasonPlayProps) => {

  const {data} = useQuery(seasonGet, {
    variables: {
      id: props?.seasonId
    },
    fetchPolicy: "cache-first",
  })

  const [isEditable, updateIsEditable] = useState(false)

  const userIsAdmin = true

  const {season} = data || {}
  const episodeList = season?.Episode

  const CustomToggle = ({children, eventKey}: {children: any, eventKey: string}) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () => { });
    return (
      <div onClick={decoratedOnClick}>
        {children}
      </div>
    );
  }

  return <div className="season-container">
    <Card>
      <Card.Header>
        <CustomToggle eventKey={`season_${props?.seasonId}`}>
          <span>Saison {season?.number} </span> <SeasonStatus status={season?.status} />
        </CustomToggle>
        {userIsAdmin && <button className="btn btn-sm btn-secondary" onClick={() => updateIsEditable(!isEditable)}>Editer</button>}
      </Card.Header>
      <Accordion.Collapse eventKey={`season_${props?.seasonId}`}>
        <Card.Body>
          <ul>
            {episodeList?.map((episode: any) => {
              const {isViewable, Media} = episode
              const duration = Media?.duration
              const nbSession = Media?.Session?.length
              const hasSession = (nbSession > 0)
              const lastSession = hasSession && Media?.Session[0]

              const percent = hasSession && Number(Number(lastSession?.progress) * 100 / Number(duration))

              return <li key={`episode_${episode?.id}`} className={(isViewable) ? "" : "notViewable"}>
                {!isEditable && <div>
                  {isViewable && <div>
                    <Link to={URL_FRONT_EPISODE_PLAY.replace(`:id`, episode.id)}>Episode {episode?.number} - {episode?.name}</Link>
                    {hasSession && <div className="progress-container"><ProgressBar now={percent} /></div>}
                  </div>}
                  {!isViewable && <span>Episode {episode?.number} - {episode?.name}</span>}
                </div>}
                {isEditable && <EpisodeUpdate episode={episode} />}
              </li>
            })}
          </ul>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  </div>
}