import ApolloClient from 'apollo-client';
import {ApolloLink} from 'apollo-link';
import {HttpLink} from 'apollo-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory'
import {createBrowserHistory} from 'history'
import {WebSocketLink} from 'apollo-link-ws';
import {split} from 'apollo-link';
import {getMainDefinition} from 'apollo-utilities';
import {findToken} from './User';

export const history = createBrowserHistory();

export const getClient = () => {

  const wsLink = new WebSocketLink({
    // uri: `wss://zipflix.localhost:4000/subscriptions`,
    uri: process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_ENDPOINT,
    options: {
      reconnect: true,
      connectionParams: {
        bearer: findToken()
      },
    }
  });


  const httpLink = new HttpLink({uri: process.env.REACT_APP_GRAPHQL_ENDPOINT});
  const AuthLink = new ApolloLink((operation: any, next: any) => {
    operation.setContext((context: any) => {
      const {loginToken} = context
      const token = loginToken || findToken()
      return {
        ...context,
        headers: {
          ...context.headers,
          Authorization: `Bearer ${token}`,
        }
      }
    });

    return next(operation);
  });

  const HttpLinkWithBearer = ApolloLink.from([
    AuthLink,
    httpLink,
  ]);

  const link = split(
    // split based on operation type
    ({query}) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    HttpLinkWithBearer,
  );

  const cache = new InMemoryCache({
    dataIdFromObject: (object: any) => {
      return object.id || null
    },
    // @ts-ignore
  }).restore(window.__APOLLO_STATE__);

  const apolloClient = new ApolloClient({
    link,
    cache,
  });

  return apolloClient

}