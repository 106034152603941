import gql from "graphql-tag";
import MediaWatchNextFragment from "../fragment/MediaWatchNextFragment";

export default gql`

  ${MediaWatchNextFragment}

  query mediaWatchNext($id: String!) {
    media: mediaWatchNext(id: $id) {
      ...MediaWatchNextFragment
    }
  }
`