import React from "react"
import {RouteComponentProps} from "react-router-dom";
import {ShowPlay} from "components/front/Media/Show/Play/ShowPlay";

export const ShowPlayRoute = (props: RouteComponentProps) => {
  const {match} = props
  // @ts-ignore
  const id = match?.params?.id;
  return <div>
    <ShowPlay id={id} />
  </div>

}