import React, {useState} from "react"

import {useQuery, useMutation} from "@apollo/react-hooks"
import {FileSearch} from "components/backoffice/Storage/File/FileSearch"


import QUERY_SHOW from "graphql/Media/Show/query/showList"
import MUTATION_IMPORT from "graphql/Transcode/Import/mutation/importAdd"

export const ImportEdit = (props: any) => {

  const [type, updateType] = useState("")
  const [show, updateShow] = useState("")
  const [season, updateSeason] = useState("")
  const [episode, updateEpisode] = useState("")
  const [file, updateFile] = useState(null)

  const {data: {showList = []} = {}} = useQuery(QUERY_SHOW)
  const [mutate] = useMutation(MUTATION_IMPORT)

  const onSubmit = (e: any) => {
    e.preventDefault()
    mutate({
      variables: {
        Episode: episode,
        File: file?.id,
        Movie: null,
      }
    })
    .then(() => console.log("OK"))
    .catch(() => console.log("NOPE"))

  }

  const isShow = type === "show"
  // const isMovie = type === "movie"

  const showSelected = showList?.find(el => el.id === show)
  const sesonSelected = showSelected?.Season?.find((el: any) => el.id === season)

  const isFormValid = isShow ? (!!episode && !!file) : false

  return <div>
    <h1>Import Edit</h1>
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="media col-6">
          <h1>Media</h1>
          <div className="input-group">
            <span className="input-group-text">Type</span>
            <select name="type" className="form-control" value={type} onChange={e => updateType(e.target.value)}>
              <option value="" disabled>Choisissez un type</option>
              <option value="movie">Movie</option>
              <option value="show">Show</option>
            </select>
          </div>
          {isShow && <div>
            <div className="input-group">
              <span className="input-group-text">Show</span>
              <select name="show" className="form-control" value={show} onChange={e => updateShow(e.target.value)}>
                <option value="" disabled>Choisissez un show</option>
                {showList?.map(show => {
                  return <option key={`show_${show?.id}`} value={show?.id}>{show?.name}</option>
                })}
              </select>
            </div>
            {show !== "" && <div className="input-group">
              <span className="input-group-text">Season</span>
              <select name="season" className="form-control" value={season} onChange={e => updateSeason(e.target.value)}>
                <option value="" disabled>Choisissez une saison</option>
                {showSelected?.Season?.map((season: any) => {
                  return <option key={`season_${season?.id}`} value={season?.id}>{season?.number}</option>
                })}
              </select>
            </div>}
            {season !== "" && <div className="input-group">
              <span className="input-group-text">Episode</span>
              <select name="episode" className="form-control" value={episode} onChange={e => updateEpisode(e.target.value)}>
                <option value="" disabled>Choisissez un épisode</option>
                {sesonSelected?.Episode?.map((episode: any) => {
                  return <option key={`episode${episode?.id}`} value={episode?.id}>{`${episode?.number} - ${episode?.name}`}</option>
                })}
              </select>
            </div>}
          </div>}
        </div>
        <div className="file col-6">
          <h1>Fichier</h1>
          <FileSearch value={file} onSelect={(v: any) => updateFile(v)} />
        </div>
      </div>
      <div>
        {isFormValid && <button type="submit" className="btn btn-dark">Enregistrer</button>}
      </div>
    </form>
  </div>
}