import React from "react"
import {UserList} from "components/backoffice/User/List/UserList"
import {useQuery} from "@apollo/react-hooks"

import userListQuery from "graphql/User/query/userList"

import './style.scss'
import {URL_BACKOFFICE_USER_ADD} from "static/constant/url"
import {Link} from "react-router-dom"

export const UserListRoute = () => {

  const {data} = useQuery(userListQuery)
  const userList = data?.userList

  return <div className="user-list-route-container">
    <UserList userList={userList} />
    <div className="actions">
      <Link to={URL_BACKOFFICE_USER_ADD}><button className="btn btn-dark">Ajouter</button></Link>
    </div>
  </div>
}