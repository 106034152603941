import gql from "graphql-tag"

export default gql`
  query searchEpisode($Show: String!, $season: Int!, $episode: Int!) {
    search: tmdbSearchEpisode(
      Show: $Show
      season: $season
      episode: $episode
    )
  }
`