import gql from "graphql-tag";
import SessionLatestFragment from "../fragment/SessionLatestFragment";

export default gql`

  ${SessionLatestFragment}

  query sessionListLatest {
    sessionList: sessionListLatest {
      ...SessionLatestFragment
    }
  }

`