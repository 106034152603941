
export enum TmdbImageSizeEnum {
  W154 = "w154",
  W185 = "w185",
  W342 = "w342",
  W500 = "w500",
}

export const getImageUrlFromSizeAndPath = (size: TmdbImageSizeEnum, path: string) => {
  const IMAGE_ROOT_URL = "https://image.tmdb.org/t/p"
  return `${IMAGE_ROOT_URL}/${size}${path}`
}