import gql from "graphql-tag";
import SeasonFragment from "../fragment/SeasonFragment";

export default gql`

  ${SeasonFragment}

  query seasonGet($id: String!) {
    season: seasonGet(id: $id) {
      ...SeasonFragment
    }
  }
`