import React, {useContext} from "react"
import Card from "react-bootstrap/Card"
import Accordion from "react-bootstrap/Accordion"
import {useMutation, useQuery, useSubscription} from "@apollo/react-hooks"
import {useAccordionToggle} from "react-bootstrap/AccordionToggle"

import MUTATION_IMPORT_START from "graphql/Transcode/Import/mutation/importStart"
import importGetQuery from "graphql/Transcode/Import/query/importGet"
import importDeleteMutation from "graphql/Transcode/Import/mutation/importDelete"
import importListQuery from "graphql/Transcode/Import/query/importList"

import {FileDetails} from "components/backoffice/Storage/File/Details/FileDetails"
import {Stepper} from "components/backoffice/Navigation/Stepper/Stepper"
import {JobList} from "../../Job/List/JobList"
import {MediaDetails} from "components/backoffice/Media/Media/Details/MediaDetails"
import IMPORT_SUBSCRIPTION from "graphql/Transcode/Import/subscription/importUpdate"
import {alertContext} from "context/Alert/alert"


type ImportDetailsProps = {
  importId: any,
}

export const ImportDetails = (props: ImportDetailsProps) => {

  const importId = props?.importId

  const {setMessage, setError} = useContext(alertContext)

  useSubscription(IMPORT_SUBSCRIPTION, {
    variables: {
      id: importId,
    },
    // onSubscriptionData: response => {
    //   console.log({response: response.subscriptionData.data})
    // }
  });

  const [importDeleteMutate] = useMutation(importDeleteMutation, {
    variables: {
      importId,
    },
    refetchQueries: [{
      query: importListQuery,
    }]
  })

  const {data} = useQuery(importGetQuery, {
    skip: !importId,
    variables: {
      id: importId,
    }
  })

  const importStartMutationHook = useMutation(MUTATION_IMPORT_START)

  const importItem = data?.importItem;
  const isStartable = importItem?.status !== "DONE"
  const {File} = importItem || {}

  const startJob = (importId: string) => {
    importStartMutationHook[0]({
      variables: {
        id: importId
      }
    })
      .then(() => console.log("OK"))
      .catch(() => console.log("NOPE"))
  }


  const CustomToggle = ({children, eventKey}: {children: any, eventKey: string}) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {});
    return (
      <span onClick={decoratedOnClick}>
        {children}
      </span>
    );
  }

  const handleDelete = () => {
    importDeleteMutate()
      .then(() => {
        setMessage("OK")
      })
      .catch(() => {
        setError("Oups...")
      })
  }

  const renderStatus = (importItem: any) => {
    const isRunning = importItem?.isRunning
    const hasError = importItem?.hasError

    switch (importItem?.status) {

      case "INITIAL": {
        return <span><span className="badge badge-info">INITIAL</span>{isRunning && <span className="icon-spinner"></span>}{hasError && <span className="badge badge-danger">ERREUR</span>}</span>
      }
      case "DONE": {
        return <span className="badge badge-success">DONE</span>
      }
      default: {
        return <span><span className="badge badge-warning">{importItem?.status}</span>{isRunning && <span className="icon-spinner"></span>}{hasError && <span className="badge badge-danger">ERREUR</span>}</span>
      }
    }
  }

  return <div>
    <Accordion>
      <Card bg={"dark"}>
        <CustomToggle eventKey={`0`}>
          <Card.Header>{File?.name} {renderStatus(importItem)}</Card.Header>
        </CustomToggle>
        <Accordion.Collapse eventKey={`0`}>
          <div>
            <Card.Body>
              <div>
                <Stepper activeValue={importItem?.status} stepList={[
                  {
                    value: "INITIAL",
                    content: () => <div key="content_initial">
                      <h1>En attente</h1>
                      {isStartable && <button className="btn btn-info" onClick={() => startJob(importItem?.id)}>Start</button>}
                    </div>
                  },
                  {
                    value: "LOADING_SOURCE",
                    content: () => <div key="source">
                      <h1>Récupération de la source</h1>
                      <FileDetails File={importItem?.File} />
                    </div>
                  },
                  {
                    value: "CREATING_JOBS",
                    content: () => <div key="jobs">
                      <h1>Génération des Jobs</h1>
                    </div>
                  },
                  {
                    value: "TRANSCODING",
                    content: () => <div key="transcoding">
                      <h1>Transcode Jobs</h1>
                      <JobList importId={importItem?.id} />
                    </div>
                  },
                  {
                    value: "UPLOADING",
                    content: () => <div key="uploading">
                      <MediaDetails mediaId={importItem?.Media?.id} />
                    </div>
                  },
                  {
                    value: "CLEANING",
                    content: () => <div key="cleaning">
                      <h1>CLEANING</h1>
                    </div>
                  },
                  {
                    value: "DONE",
                    content: () => <div key="done">
                      <h1>DONE</h1>
                    </div>
                  },
                ]} />
              </div>
            </Card.Body>
            <Card.Footer>
              <button className="btn btn-danger" onClick={() => handleDelete()}>Supprimer</button>
            </Card.Footer>
          </div>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </div>

}
