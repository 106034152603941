import React from "react"
import {useSubscription} from "@apollo/react-hooks";

import ProgressBar from "react-bootstrap/ProgressBar"

import JOB_SUBSCRIPTION from "graphql/Transcode/Job/subscription/jobUpdate"

export const JobStatus = (props: any) => {

  const {data} = useSubscription(
    JOB_SUBSCRIPTION,
  );

  const progress = data?.jobUpdate?.progress || 0
  const isJobDone = data?.jobUpdate?.status === "DONE"
  const isJobFailed = data?.jobUpdate?.status === "ERROR"
  const isInProgress = data?.jobUpdate?.status === "RUNNING"
  const variant = isJobDone ? "success" : isJobFailed ? "danger" : "info"

  return <div>
    <h1>Job Status</h1>
    <div>
      <ProgressBar animated={isInProgress} variant={variant} now={progress} />
    </div>
  </div>

}