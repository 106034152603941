import React from "react"

import FileUpdateSubscription from "graphql/Storage/File/subscription/fileUpdate"
import {useSubscription} from "@apollo/react-hooks"
import ProgressBar from "react-bootstrap/ProgressBar"

import "./style.scss"

type FileDetailsProps = {
  File: any,
}

export const FileDetails = (props: FileDetailsProps) => {
  useSubscription(FileUpdateSubscription, {
    skip: !props?.File?.id,
    variables: {
      id: props?.File?.id
    }
  })

  const transferProgress = props?.File?.transferProgress || 0

  return <div className="file-details-container">
    <span>Transfert du fichier {props?.File?.id}</span>
    <ProgressBar now={transferProgress} />
  </div>


}