

export const URL_BACKOFFICE = `/backoffice`

export const URL_BACKOFFICE_TRANSCODE_IMPORT_ADD = `${URL_BACKOFFICE}/transcode/import/add`
export const URL_BACKOFFICE_TRANSCODE_IMPORT_ADD_LIST = `${URL_BACKOFFICE}/transcode/import/add/list`

export const URL_BACKOFFICE_TRANSCODE_IMPORT_EDIT = `${URL_BACKOFFICE}/transcode/import/edit/:id`
export const URL_BACKOFFICE_TRANSCODE_IMPORT_LIST = `${URL_BACKOFFICE}/transcode/import/list`
export const URL_BACKOFFICE_TRANSCODE_JOB_STATUS = `${URL_BACKOFFICE}/transcode/job/status/:id`
export const URL_BACKOFFICE_TRANSCODE_JOB_LIST = `${URL_BACKOFFICE}/transcode/job/list`

export const URL_BACKOFFICE_STORAGE_PROVIDER_LIST = `${URL_BACKOFFICE}/storage/provider/list`
export const URL_BACKOFFICE_STORAGE_PROVIDER_ADD = `${URL_BACKOFFICE}/storage/provider/add`
export const URL_BACKOFFICE_STORAGE_PROVIDER_EDIT = `${URL_BACKOFFICE}/storage/provider/edit/:id`
export const URL_BACKOFFICE_STORAGE_PROVIDER_SCAN = `${URL_BACKOFFICE}/storage/provider/scan`

export const URL_BACKOFFICE_MEDIA_SHOW_ADD = `${URL_BACKOFFICE}/media/show/add`
export const URL_BACKOFFICE_MEDIA_SHOW_EDIT = `${URL_BACKOFFICE}/media/show/edit/:id`
export const URL_BACKOFFICE_MEDIA_COLLECTION_ADD = `${URL_BACKOFFICE}/media/collection/add`
export const URL_BACKOFFICE_MEDIA_MOVIE_ADD = `${URL_BACKOFFICE}/media/movie/add`

export const URL_BACKOFFICE_MEDIA_EPISODE_ADD = `${URL_BACKOFFICE}/media/episode/add`
export const URL_BACKOFFICE_MEDIA_EPISODE_EDIT = `${URL_BACKOFFICE}/media/episode/edit/:id`

export const URL_BACKOFFICE_USER_ADD = `${URL_BACKOFFICE}/user/add`
export const URL_BACKOFFICE_USER_LIST = `${URL_BACKOFFICE}/user/list`
export const URL_BACKOFFICE_USER_EDIT = `${URL_BACKOFFICE}/user/edit/:id`

export const URL_FRONT = "/"
export const URL_FRONT_SHOW_PLAY = `${URL_FRONT}show/:id/play`
export const URL_FRONT_EPISODE_PLAY = `${URL_FRONT}episode/:id/play`
export const URL_FRONT_COLLECTION_PLAY = `${URL_FRONT}collection/:id/play`
export const URL_FRONT_MOVIE_PLAY = `${URL_FRONT}movie/:id/play`
export const URL_FRONT_SEARCH = `${URL_FRONT}search`