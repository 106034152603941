import gql from "graphql-tag";

export default gql`
  query userList {
    userList: userList {
      id
      email
      isAdmin
    }
  }
`