import React, {useState, useEffect} from 'react';

import {Controlled as CodeMirrorField} from 'react-codemirror2'
import 'codemirror/mode/javascript/javascript';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

type CodeMirrorProps = {
  onChange: (value: string) => void,
  value: string,
}

export const CodeMirror = (props: CodeMirrorProps) => {

  const {onChange, value} = props
  const stringifiedValue = value && JSON.stringify(value)

  const [stateValue, updateStateValue] = useState(stringifiedValue)
  const [hasError, updateError] = useState(false)

  useEffect(() => {
    updateStateValue(stringifiedValue)
  }, [stringifiedValue])

  const handleChange = (value: string) => {
    updateStateValue(value)
    try {
      const valueParsed =value && JSON.parse(value)
      updateError(false)
      onChange(valueParsed)
    } catch (e) {
      updateError(true)
      console.log(e.message)
      console.log("NOT VALID")
    }
  }

  return <div>
    <div>
      {hasError && <div>
        <span><span className="icon-warning-sign"></span>Not valid JSON</span>
      </div>}
    </div>
    <div>
      <CodeMirrorField
        value={stateValue}
        options={{
          mode: "application/json",
          theme: 'material',
          lineNumbers: true,
        }}
        onBeforeChange={(editor, data, value) => {
          handleChange(value)
        }}
      />
    </div>
  </div>
}