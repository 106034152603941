import gql from "graphql-tag";
import EpisodeFragment from "../fragment/EpisodeFragment";

export default gql`

  ${EpisodeFragment}

  mutation episodeUpdate($id: String!, $input: EpisodeUpdateInput!) {
    episode: episodeUpdate(id: $id, input: $input) {
      ...EpisodeFragment
    }
  }

`