import React, {useState, FormEvent, useContext} from "react"
import {useMutation} from "@apollo/react-hooks"
import {Search} from "../Tmdb/Search"

import MUTATION_SHOW_ADD from "graphql/Media/Show/mutation/showAdd"
import {alertContext} from "context/Alert/alert"

export const ShowEdit = (props: any) => {


  const [mutate] = useMutation(MUTATION_SHOW_ADD)
  const {setMessage, setError} = useContext(alertContext)

  const [showName, updateName] = useState("")
  const [showTmdbOptions, updateTmdbOptions] = useState(null)

  const onChange = (name: string, tmdbOptions: any) => {
    updateName(name)
    updateTmdbOptions(tmdbOptions)
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    mutate({
      variables: {
        name: showName,
        tmdbOptions: showTmdbOptions
      }
    })
      .then(() => {
        setMessage(<h1>OK :)</h1>)
      })
      .catch(() => {
        setError(<h1>Oups :)</h1>)
      })
  }

  return <div>
    <h1>Show</h1>
    {showName && <h2>{showName}</h2>}
    <form onSubmit={onSubmit}>
      <div className="col-sm-6">
        <div className="input-group">
          <Search
            type='show'
            onChange={onChange}
          />
        </div>
        <button className="btn btn-dark">Enregister</button>
      </div>
    </form>
  </div>
}