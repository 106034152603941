import React, {useContext, useEffect, useState} from "react"


import "./style.scss";
import {alertContext} from "context/Alert/alert";
import ProgressBar from "react-bootstrap/ProgressBar";

export const Alert = () => {

  const [progress, updateProgress] = useState(0)
  const {message, type, isVisible, duration} = useContext(alertContext)

  const effectDuration = Number(duration-500);
  const refreshTime = Number(effectDuration / 10)

  const looping = (step: number) => {
    updateProgress(step)
    if (step >= 100) {
    } else {
      setTimeout(() => {
        looping(step + 10)
      }, refreshTime)
    }
  }

  useEffect(() => {
    if (isVisible) {
      looping(0)
    }
  }, [isVisible])

  // const className = `alert-item ${(type === "error") ? "error" : ""} ${(message) ? "message" : ""} ${(isVisible) ? "" : "alert-hidden"}`
  const className = `alert-item ${type} ${(isVisible) ? "" : "alert-hidden"}`
  const classNameBanner = isVisible ? "alert-banner" : "alert-banner hidden"


  // console.log({progress})

  return <div className={classNameBanner}>
    <div className={className}>
      <div className="content">
        {message}
      </div>
      <div className="progress-container">
        <ProgressBar now={progress} />
      </div>
    </div>
  </div>

}