import gql from "graphql-tag";

export default gql`

  fragment CollectionSearchFragment on Collection {
    id
    name
    tmdbOptions
    status
  }

`