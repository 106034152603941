import gql from "graphql-tag";
import EpisodeFragment from "graphql/Media/Episode/fragment/EpisodeFragment";

export default gql`

  ${EpisodeFragment}

  fragment SeasonFragment on Season {
    id
    number
    status
    Episode {
      ...EpisodeFragment
    }
  }

`