import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import BootstrapBreadcrumb from "react-bootstrap/Breadcrumb"

import {URL_BACKOFFICE, URL_BACKOFFICE_TRANSCODE_IMPORT_ADD, URL_BACKOFFICE_TRANSCODE_IMPORT_ADD_LIST, URL_BACKOFFICE_TRANSCODE_IMPORT_LIST, URL_BACKOFFICE_STORAGE_PROVIDER_LIST, URL_BACKOFFICE_STORAGE_PROVIDER_ADD, URL_BACKOFFICE_MEDIA_COLLECTION_ADD, URL_BACKOFFICE_MEDIA_SHOW_ADD} from "static/constant/url"
import "./style.scss"

export const BreadCrumb = (props: any) => {

  const location = useLocation()

  switch (location?.pathname) {
    case URL_BACKOFFICE_TRANSCODE_IMPORT_ADD_LIST:
    case URL_BACKOFFICE_TRANSCODE_IMPORT_ADD: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_BACKOFFICE}>Backoffice</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Transcode</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Import</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Ajouter</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    case URL_BACKOFFICE_TRANSCODE_IMPORT_LIST: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_BACKOFFICE}>Backoffice</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Transcode</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Import</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Lister</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    case URL_BACKOFFICE_STORAGE_PROVIDER_LIST: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_BACKOFFICE}>Backoffice</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Storage</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Provider</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Lister</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    case URL_BACKOFFICE_STORAGE_PROVIDER_ADD: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_BACKOFFICE}>Backoffice</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Storage</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Provider</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Ajouter</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    case URL_BACKOFFICE_MEDIA_COLLECTION_ADD: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_BACKOFFICE}>Backoffice</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Media</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Collection</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Ajouter</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    case URL_BACKOFFICE_MEDIA_SHOW_ADD: {
      return <BootstrapBreadcrumb>
        <BootstrapBreadcrumb.Item><Link to={URL_BACKOFFICE}>Backoffice</Link></BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Media</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Show</BootstrapBreadcrumb.Item>
        <BootstrapBreadcrumb.Item active>Ajouter</BootstrapBreadcrumb.Item>
      </BootstrapBreadcrumb>
    }

    default: {
      return <div />
    }
  }
}
