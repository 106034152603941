import gql from "graphql-tag";
import SessionFragment from "../fragment/SessionFragment";

export default gql`

  ${SessionFragment}

  mutation sessionAdd($input: SessionInput!) {
    session: sessionAdd(input: $input) {
      ...SessionFragment
    }
  }

`