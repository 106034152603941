import gql from "graphql-tag"
import SessionFragment from "graphql/Streaming/Session/fragment/SessionFragment"

export default gql`

  ${SessionFragment}

  fragment MediaWatchNextFragment on Media {
    id
    duration
    Episode {
      id
      name
      tmdbOptions
    }
    Movie {
      id
      name
      tmdbOptions
    }
    Version {
      id
    }
    Session {
      ...SessionFragment
    }
  }
`