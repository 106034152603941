import gql from "graphql-tag"
import ImportFragment from "../fragment/ImportFragment"

export default gql`

  ${ImportFragment}

  mutation importAddList($input: [ImportInput!]!) {
    importAddList(input: $input) {
      ...ImportFragment
    }
  }
`