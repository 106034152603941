import gql from "graphql-tag";
import ProviderFragment from "../fragment/ProviderFragment";

export default gql`

  ${ProviderFragment}


  query providerGet($id: String!) {
    provider: providerGet(id: $id) {
      ...ProviderFragment
    }
  }
`