import gql from "graphql-tag";
import ProviderFragment from "../fragment/ProviderFragment";

export default gql`

  ${ProviderFragment}

  query providerList {
    providerList {
      ...ProviderFragment
    }
  }

`