import React from "react"
import {useQuery, useSubscription} from "@apollo/react-hooks"
import mediaGetDetails from "graphql/Media/Media/query/mediaGetDetails"
import {FileDetails} from "components/backoffice/Storage/File/Details/FileDetails"

import './style.scss'
import mediaUpdate from "graphql/Media/Media/subscription/mediaUpdate"

type MediaDetailsProps = {
  mediaId: string,
}

export const MediaDetails = (props: MediaDetailsProps) => {

  useSubscription(mediaUpdate, {
    skip: !props?.mediaId,
    variables: {
      id: props?.mediaId,
    }
  })

  const {data} = useQuery(mediaGetDetails, {
    skip: !props?.mediaId,
    variables: {
      id: props?.mediaId
    }
  })

  const media = data?.media
  const versionList = media?.Version

  return <div className="media-details-container">
    <h1>Media</h1>
    <h2>Versions</h2>
    {versionList?.map((version: any) => {
      return <div key={`version_${version?.id}`}>
        <h3>Version {version.language} / {version?.id}</h3>
        <FileDetails File={version?.File} />
      </div>
    })}
    <h2>Subtitles</h2>
    <div>TODO</div>
  </div>
}