import gql from "graphql-tag";
import MovieFragment from "graphql/Media/Movie/fragment/MovieFragment";

export default gql`

  ${MovieFragment}

  fragment CollectionFragment on Collection {
    id
    name
    tmdbOptions
    status
    Movie {
      ...MovieFragment
    }
  }

`