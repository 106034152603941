import React from "react"

import {RouteComponentProps} from "react-router-dom"
import {CollectionPlay} from "components/front/Media/Collection/Play/CollectionPlay"

export const CollectionPlayRoute = (props: RouteComponentProps) => {

  const {match} = props
  const {params} = match
  // @ts-ignore
  const collectionId = params?.id

return <div className="episode-play-route">
    <CollectionPlay id={collectionId} />
  </div>
}