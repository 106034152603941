import React from "react"
import {useQuery} from "@apollo/react-hooks"

import showList from "graphql/Media/Show/query/showList"
import collectionList from "graphql/Media/Collection/query/collectionList"
import sessionListLatest from "graphql/Streaming/Session/query/sessionListLatest"


import {MediaSlider, MediaTypeEnum} from "components/front/Media/Slider"

import './style.scss'
import movieListSingle from "graphql/Media/Movie/query/movieListSingle"

export const MainRoute = (props: any) => {

  const showListData = useQuery(showList)
  const movieListData = useQuery(movieListSingle)
  const collectionListData = useQuery(collectionList)
  const sessionListData = useQuery(sessionListLatest, {
    fetchPolicy: "cache-and-network",
  })

  const hasSessions = sessionListData?.data?.sessionList?.length > 0

  return <div className="main-route-container">
    {hasSessions && <div>
      <h1>Reprendre</h1>
      <div className="show-slider">
        <MediaSlider elementList={sessionListData?.data?.sessionList} type={MediaTypeEnum.SESSION} />
      </div>
    </div>}
    <div><h1>Collection</h1>
      <div className="show-slider">
        <MediaSlider elementList={collectionListData?.data?.list} type={MediaTypeEnum.COLLECTION} />
      </div>
    </div>
    <div><h1>Films</h1>
      <div className="show-slider">
        <MediaSlider elementList={movieListData?.data?.movieList} type={MediaTypeEnum.MOVIE} />
      </div>
    </div>
    <div><h1>Séries</h1>
      <div className="show-slider">
        <MediaSlider elementList={showListData?.data?.showList} type={MediaTypeEnum.SHOW} />
      </div>
    </div>
  </div>
}