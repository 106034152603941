import gql from "graphql-tag";
import ProviderFragment from "../fragment/ProviderFragment";

export default gql`

  ${ProviderFragment}

  mutation providerAdd($input: StorageProviderInput!) {
    provider: providerAdd(input: $input) {
      ...ProviderFragment
    }
  }
`