import gql from "graphql-tag"

export default gql`
  fragment showFragment on Show {
    id
    name
    tmdbOptions
    status
    Season {
      id
      number
    }
  }
`