import React from "react"
import {TmdbImageSizeEnum, getImageUrlFromSizeAndPath} from "helpers/Tmdb"

type TmdbImageProps = {
  size: TmdbImageSizeEnum,
  path: string,
}

export const TmdbImage = (props: TmdbImageProps) => {
  const url = getImageUrlFromSizeAndPath(props?.size, props?.path)
  const urlValid  = (props?.size && props?.path)

  return <div>
    {urlValid && <img alt='episode_picture' src={url} className="img-fluid"/>}
  </div>
}