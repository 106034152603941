import gql from "graphql-tag";

export default gql`
  query userProfile {
    userProfile {
      id
      email
      isAdmin
    }
  }
`