import React from "react"
import {CodeMirror} from "components/backoffice/CodeMirror/CodeMirror"

export type GdriveOptionsProps = {
  options: any,
  onChange: (value: string) => void
}

export const GdriveOptions = (props: GdriveOptionsProps) => {

  const {options, onChange} = props

  return <div className="gdrive-options-container">
    <div className="input-group">
      <span className="input-group-text">Email</span>
      <input type="text" className="form-control" name="emailToImpersonate" value={options?.emailToImpersonate} onChange={(e) => onChange({
        ...options,
        emailToImpersonate: e.target.value,
      })} />
    </div>
    <div className="input-group">
      <span className="input-group-text">Root Folder Id</span>
      <input type="text" className="form-control" name="rootParentId" value={options?.rootParentId} onChange={(e) => onChange({
        ...options,
        rootParentId: e.target.value,
      })} />
    </div>
    <div>
      <CodeMirror
        value={options?.credentials}
        onChange={(value) => onChange({
          ...options,
          credentials: value,
        })}
      />
    </div>
  </div>
}