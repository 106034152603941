import gql from "graphql-tag";
import EpisodeFragment from "graphql/Media/Episode/fragment/EpisodeFragment";
import MovieFragment from "graphql/Media/Movie/fragment/MovieFragment";

export default gql`

  ${EpisodeFragment}
  ${MovieFragment}

  fragment JobFragment on Job {
    id
    progress
    status
    type
    language
    Import {
      id
      Media {
        id
        Episode {
          ...EpisodeFragment
        }
        Movie {
          ...MovieFragment
        }
      }
    }
  }
`