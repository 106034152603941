import gql from "graphql-tag";
import CollectionFragment from "../fragment/CollectionFragment";

export default gql`

  ${CollectionFragment}

  mutation collectionAdd($input: CollectionInput!) {
    collectionAdd(input: $input) {
      ...CollectionFragment
    }
  }
`