import React, {useState, useContext} from "react"
import Card from "react-bootstrap/Card"
import providerGet from "graphql/Storage/Provider/query/providerGet"
import {useQuery, useMutation} from "@apollo/react-hooks"
import {CodeMirror} from "components/backoffice/CodeMirror/CodeMirror"


// import {Controlled as CodeMirror} from 'react-codemirror2'
// import 'codemirror/mode/javascript/javascript';
// import 'codemirror/lib/codemirror.css';
// import 'codemirror/theme/material.css';

import providerEdit from "graphql/Storage/Provider/mutation/providerEdit"
import providerAdd from "graphql/Storage/Provider/mutation/providerAdd"
import {alertContext} from "context/Alert/alert"

import "./style.scss"
import {GdriveOptions} from "./Gdrive/GdriveOptions"
import {S3Options} from "./S3/S3Options"

type ProviderEditProps = {
  id?: string,
  open?: boolean,
}

export const ProviderEdit = (props: ProviderEditProps) => {

  const {id, open} = props
  const {setMessage, setError} = useContext(alertContext)

  const [isOpen, updateIsOpen] = useState(open || false)
  const [providerValue, updateProviderValue] = useState(null)
  const [valueUpdated, updateValueUpdated] = useState(true)

  const updateMutation = useMutation(providerEdit)
  const addMutation = useMutation(providerAdd)

  const {data} = useQuery(providerGet, {
    skip: !id,
    variables: {
      id,
    },
    onCompleted: (data) => {
      const provider = data?.provider
      // const optionsStringified = provider?.options && JSON.stringify(provider?.options) || ""
      updateProviderValue({
        ...provider,
        enableScan: (provider?.enableScan) ? "1" : "0",
        // options: optionsStringified,
        options: provider?.options,
      })
    }
  })

  const originalProvider = {
    ...data?.provider,
    options: data?.provider?.options,
  }

  const onChange = (provider: any) => {
    updateValueUpdated(true)
    updateProviderValue(provider)
  }

  const resetChange = () => {
    updateProviderValue(originalProvider)
    updateValueUpdated(false)
  }

  const saveChanges = () => {
    if (id) {
      const {id, __typename, ...rest} = providerValue
      updateMutation[0]({
        variables: {
          id,
          input: {
            ...rest,
            enableScan: (providerValue?.enableScan === "1") ? true : false,
            // options: JSON.parse(providerValue?.options),
          }
        }
      })
        .then(() => {
          setMessage(<h1>OK</h1>)
          updateValueUpdated(false)
        })
        .catch(() => setError(<h1>Oups :(</h1>))
    } else {
      addMutation[0]({
        variables: {
          input: {
            ...providerValue,
            enableScan: (providerValue?.enableScan === "1") ? true : false,
            // options: JSON.parse(providerValue?.options),
          }
        }
      })
        .then(() => {
          setMessage(<h1>OK</h1>)
          updateValueUpdated(false)
        })
        .catch(() => setError(<h1>Oups :(</h1>))
    }
  }

  const _renderOptions = (type: string, value: any) => {
    switch (type) {
      case "GDRIVE": {
        return <GdriveOptions
          options={value}
          onChange={(value: any) => {
            onChange({
              ...providerValue,
              options: value,
            })
          }}
        />
      }
      case "S3": {
        return <S3Options
          options={value}
          onChange={(value: any) => {
            onChange({
              ...providerValue,
              options: value,
            })
          }}
        />
      }
      default: {
        const optionsStringified = providerValue?.options && JSON.stringify(providerValue?.options)
        return <CodeMirror
          value={optionsStringified}
          onChange={(value) => console.log(value)}
        />
      }
    }
  }

  return <div className="provider-edit-container">
    <Card>
      <Card.Header>
        <span onClick={() => updateIsOpen(!isOpen)}>
          {providerValue?.name}
        </span>
      </Card.Header>
      <div className={isOpen ? "collapse-container collapse-visible" : "collapse-container"}>
        <Card.Body>
          <div>
            <div className="input-group">
              <span className="input-group-text">Nom</span>
              <input className="form-control" type="text" value={providerValue?.name || ""} onChange={e => onChange({
                ...providerValue,
                name: e.target.value,
              })} />
            </div>
            <div className="input-group">
              <span className="input-group-text">Type</span>
              <select className="form-control" value={providerValue?.type || ""} onChange={e => onChange({
                ...providerValue,
                type: e.target.value,
              })}>
                <option value="" disabled>Choisissez un type</option>
                <option value="GDRIVE">GDRIVE</option>
                <option value="S3">S3</option>
              </select>
            </div>
            <div className="input-group">
              <span className="input-group-text">Scan Enabled</span>
              <select className="form-control" value={providerValue?.enableScan} onChange={e => onChange({
                ...providerValue,
                enableScan: e.target.value,
              })}>
                <option value="" disabled>Choisissez un type</option>
                <option value="0">Non</option>
                <option value="1">Oui</option>
              </select>
            </div>
            <div className="input-group">
              <span className="input-group-text">Target</span>
              <select className="form-control" value={providerValue?.target || ""} onChange={e => onChange({
                ...providerValue,
                target: e.target.value,
              })}>
                <option value="" disabled>Choisissez une cible</option>
                <option value="MEDIA">MEDIA</option>
                <option value="SOURCE">SOURCE</option>
              </select>
            </div>
            <div>
              {_renderOptions(providerValue?.type, providerValue?.options)}
            </div>
            {valueUpdated && <div>
              <button className="btn btn-dark" type="button" onClick={saveChanges}>Enregister</button>
              <button className="btn btn-danger" type="button" onClick={resetChange}>Annuler</button>
            </div>}
          </div>
        </Card.Body>
      </div>
    </Card>
  </div>
}