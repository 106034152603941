import gql from "graphql-tag";

export default gql`
  fragment ProviderFragment on StorageProvider {
    id
    name
    type
    target
    enableScan
    options
  }
`