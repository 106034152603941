import {useMutation, useQuery} from "@apollo/react-hooks"
import episodeUpdate from "graphql/Media/Episode/mutation/episodeUpdate"
import episodeGet from "graphql/Media/Episode/query/episodeGet"
import React, {useEffect, useState} from "react"

type EpisodeUpdateProps = {
  episode: any,
}

export const EpisodeUpdate = (props: EpisodeUpdateProps) => {

  const [episode, updateEpisode] = useState<any>(props?.episode)
  const [formUpdated, updateFormUpdated] = useState<boolean>(false)

  // const {data} = useQuery(episodeGet, {
  //   variables: {
  //     id: props?.episodeId
  //   },
  //   fetchPolicy: "cache-first",
  //   onCompleted: (data) => {
  //     updateEpisode(data?.episode)
  //   }
  // })

  useEffect(() => {
    if (!episode) {
      updateEpisode(props?.episode)
    }
  }, [props?.episode])

  const [mutate] = useMutation(episodeUpdate)

  const updateForm = (v: any) => {
    updateFormUpdated(true)
    updateEpisode(v)
  }

  const onSave = () => {
    mutate({
      variables: {
        id: episode?.id,
        input: {
          number: episode?.number,
          name: episode?.name,
          isVisible: episode?.isVisible,
        }
      }
    })
    .then(() => updateFormUpdated(false))
  }

  // const {episode} = data || {}

  return <div className="input-group" style={{padding: 0, paddingBottom: 0}}>
    Episode
  <div className="col-2">
      <input className="form-control form-control-sm input-normal" size={2} type="text" value={episode?.number} onChange={(e: any) => updateForm({
        ...episode,
        number: Number(e.target.value),
      })} />
    </div>
  -
  <div className="col-4">
      <input className="form-control form-control-sm input-normal" type="text" value={episode?.name} onChange={(e: any) => updateForm({
        ...episode,
        name: e.target.value,
      })} />
    </div>
    <div className="col-3">
      <select className="form-control form-control-sm input-normal" value={episode?.isVisible} onChange={(e: any) => updateForm({
        ...episode,
        isVisible: (e.target.value === "0") ? false: true,
      })} >
        <option value="1">Visible</option>
        <option value="0">Not Visible</option>
      </select>
    </div>
    {formUpdated && <div className="col-1">
      <button className="btn btn-sm btn-dark" onClick={() => onSave()}><span className="icon-save"></span></button>
    </div>}
  </div>
}