import gql from "graphql-tag";
import MovieSearchFragment from "graphql/Media/Movie/fragment/MovieSearchFragment";
import CollectionSearchFragment from "graphql/Media/Collection/fragment/CollectionSearchFragment";
import ShowSearchFragment from "graphql/Media/Show/fragment/ShowSearchFragment";
import EpisodeSearchFragment from "graphql/Media/Episode/fragment/EpisodeSearchFragment";

export default gql`

${MovieSearchFragment}
${CollectionSearchFragment}
${ShowSearchFragment}
${EpisodeSearchFragment}

query search($text: String!) {
  search(text: $text) {
    ... on Movie {
      ...MovieSearchFragment
    }
    ... on Collection {
      ...CollectionSearchFragment
    }
    ... on Show {
      ...ShowSearchFragment
    }
    ... on Episode {
      ...EpisodeSearchFragment
    }
  }
}
`