import React, {useState, FormEvent, useContext} from "react"
import {useMutation} from "@apollo/react-hooks"
import {Search} from "../Tmdb/Search"

import movieAdd from "graphql/Media/Movie/mutation/movieAdd"
import {alertContext} from "context/Alert/alert"

export const MovieEdit = (props: any) => {

  const [mutate] = useMutation(movieAdd)

  const {setMessage, setError} = useContext(alertContext)
  const [movie, updateMovie] = useState<any>(null)

  const onChange = (name: string, tmdbOptions: any) => {
    updateMovie({
      name,
      tmdbOptions,
    })
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    mutate({
      variables: {
        input: {
          ...movie,
        }
      }
    })
      .then(() => {
        setMessage(<h1>OK :)</h1>)
      })
      .catch(() => {
        setError(<h1>Oups :)</h1>)
      })
  }

  return <div>
    <h1>Movie</h1>
    <form onSubmit={onSubmit}>
      <div className="col-sm-6">
        <div className="input-group">
          <Search
            type='movie'
            onChange={onChange}
          />
        </div>
        <button className="btn btn-dark">Enregister</button>
      </div>
    </form>
  </div>
}