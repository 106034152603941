
import gql from "graphql-tag"

export default gql`
  subscription {
    jobUpdate {
      id
      progress
      status
    }
  }
`
