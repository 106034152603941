import gql from "graphql-tag";

import EpisodeFragment from "../fragment/EpisodeFragment"

export default gql`

  ${EpisodeFragment}

  query episodeGet($id: String!) {
    episode: episodeGet(id: $id) {
      ...EpisodeFragment
    }
  }

`