import gql from "graphql-tag"
import ImportDetailsFragment from "../fragment/ImportDetailsFragment"

export default gql`

  ${ImportDetailsFragment}

  query importGet($id: String!) {
    importItem: importGet(id: $id) {
      ...ImportDetailsFragment
    }
  }
`