import React from 'react'
import {useQuery} from '@apollo/react-hooks'

// import {VersionType} from "../../../../../../../common/model/Media/VersionType"

import episodeGet from 'graphql/Media/Episode/query/episodeGet'
import {MediaPlay} from '../../Media/Play/MediaPlay'

type EpisodePlayProps = {
  id: string,
}

export const EpisodePlay = (props: EpisodePlayProps) => {

  // const [versionSelected, updateVersionSelected] = useState(null)

  const {data} = useQuery(episodeGet, {
    // onCompleted: data => {
    //   // console.log({data})
    //   const versionList = data?.episode?.Media?.Version;
    //   const defaultVersion = versionList[0];
    //   updateVersionSelected(defaultVersion?.id);
    // },
    variables: {
      id: props?.id,
    }
  })

  const episode = data?.episode;
  const mediaId = episode?.Media?.id
  // const versionList: VersionType[] = episode?.Media?.Version;

  // const hasManyVersions = versionList?.length >= 2


  // console.log({mediaId})

  return <div className="episode-play-container">
    <h1>Episode {episode?.number} - {episode?.name}</h1>
    {/* {hasManyVersions && <div className="input-group">
      <select className="form-control" value={versionSelected}>
        {versionList?.map((version : VersionType) => {
          const {id, language} = version
          return <option value={id}>{language}</option>
        })}
      </select>
    </div>} */}
    <div>
      <MediaPlay id={mediaId} />
    </div>
  </div>
}