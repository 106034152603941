import gql from "graphql-tag"
import FileFragment from "graphql/Storage/File/fragment/FileFragment"

export default gql`

  ${FileFragment}

  fragment MediaDetailsFragment on Media {
    id
    duration
    Version {
      id
      language
      File {
        ...FileFragment
      }
    }
  }
`