import React, {useState} from "react"
import {useQuery} from "@apollo/react-hooks"

import searchShowQuery from 'graphql/Media/Tmdb/query/searchShow'
import searchCollectionQuery from 'graphql/Media/Tmdb/query/searchCollection'
import searchMovieQuery from 'graphql/Media/Tmdb/query/searchMovie'

import "./style.scss"

let timeout: any = null;



export const Search = (props: any) => {


  const searchType = props?.type ?? "show";

  const [valueSelected, updateValueSelected] = useState(null)


  const [resultSelectedIndex, updateResultSelectedIndex] = useState(-1)
  const [text, updateText] = useState("")
  const [searchResults, updateSearchResults] = useState(null)

  let queryFile = searchShowQuery;
  switch (searchType) {
    default:
    case "show":
      break;
    case "collection":
      queryFile = searchCollectionQuery
      break;
    case "movie":
      queryFile = searchMovieQuery;
      break;
  }

  const {refetch} = useQuery(queryFile, {
    skip: true,
  })

  const searchRun = async (text: string) => {
    const response = await refetch({
      text,
    })
    updateSearchResults(response?.data?.search?.results)
  }

  const onUpdate = (event: any) => {
    const text: string = event.target.value
    updateText(text)
    clearTimeout(timeout)
    if (text?.length > 3) {
      timeout = setTimeout(() => {
        searchRun(text)
        console.log('Triggering search')
      }, 800)
    }
    return event
  }

  const onResultFocus = (index: any) => {
    updateResultSelectedIndex(index)
  }

  const resetSearch = () => {
    updateValueSelected(null);
    updateText("")
    updateSearchResults(null)
    updateResultSelectedIndex(-1)
  }


  const onResultSelected = (index: any) => {
    const elementName = searchResults[index].original_name || searchResults[index].original_title
    updateValueSelected(searchResults[index])
    updateText(elementName)
    updateSearchResults(null)
    props?.onChange(elementName, searchResults[index])
  }


  const onKeyDown = (event: any) => {
    switch (event?.key) {
      case "ArrowDown": {
        if (resultSelectedIndex === -1) {
          updateResultSelectedIndex(0);
        } else {
          // @ts-ignore
          if (resultSelectedIndex < (searchResults?.length - 1)) {
            updateResultSelectedIndex(resultSelectedIndex + 1)
          }
        }
        break;
      }

      case "ArrowUp": {
        // @ts-ignore
        if (resultSelectedIndex && resultSelectedIndex > 0) {
          updateResultSelectedIndex(resultSelectedIndex - 1)
        }
        break;
      }

      case "Enter": {
        if (resultSelectedIndex !== -1 && searchResults) {
          onResultSelected(resultSelectedIndex)
        }
        break;
      }
    }
    return event
  }

  // @ts-ignore
  const hasSearchResults = searchResults?.length > 0
  // @ts-ignore
  const hasEmptyResults = searchResults?.length === 0
  // @ts-ignore
  const hasValueSelected = !!valueSelected

  return <div className="tmdb-search">
    <div className="input-group">
      <span className="input-group-text">Rechercher</span>
      <input type="text" disabled={hasValueSelected} className="form-control" placeholder={`Rechercher un ${props.type}`} value={text} onChange={onUpdate} onKeyDown={onKeyDown} />
      {hasValueSelected && <span className="reset input-group-text" onClick={resetSearch}><span className="icon-trash" /></span>}
    </div>
    <div className="results">
      {hasSearchResults && <ul className='list-group'>
        {
          // @ts-ignore
          searchResults?.map((result, index) => {
            const title = result?.original_name || result?.original_title
            return <li key={`result_${index}`} className={index === resultSelectedIndex ? "list-group-item active" : "list-group-item"} onMouseEnter={() => onResultFocus(index)} onClick={() => onResultSelected(index)}>
              <span className='title'>{title}</span>
              {result?.overview && <span className='desc'>{result?.overview}</span>}
              {result?.first_air_date && <span className='desc'>Date de sortie: {result?.first_air_date}</span>}
              {result?.origin_country && <span className='desc'>Origine: {result?.origin_country}</span>}
            </li>
          })
        }
      </ul>}
      {hasEmptyResults && <div className="alert alert-dark">No match found</div>}
    </div>
  </div>
}