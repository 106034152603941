import gql from "graphql-tag";

export default gql`
mutation showAdd($name: String!, $tmdbOptions: JSONObject!) {
  showAdd(input: {
    name: $name
    tmdbOptions: $tmdbOptions
  }) {
    id
    name
    tmdbOptions
  }
}
`