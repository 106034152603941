import React from "react"
import {JobStatus} from "components/backoffice/Transcode/Job/JobStatus"

export const JobStatusRoute = (props: any) => {

  const {match} = props
  const {params} = match
  const {id} = params

  return <div>
    <JobStatus id={id} />
  </div>
}