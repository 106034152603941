import gql from "graphql-tag";

export default gql`

  fragment SessionFragment on Session {
    id
    progress
    lastUpdate
    sessionGroup
    VersionSelected {
      id
    }
    SubtitleSelected {
      id
    }
    Media {
      id
      duration
      Subtitle {
        id
      }
    }
  }

`