
import React from "react"
import {useQuery} from "@apollo/react-hooks"


import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import {useAccordionToggle} from "react-bootstrap/AccordionToggle"

import {TmdbImage} from "components/common/Tmdb/TmdbImage"

import {URL_FRONT_EPISODE_PLAY} from "static/constant/url"

import showGet from "graphql/Media/Show/query/showGet"
import {SeasonStatus} from "../../Season/Status/SeasonStatus"

import "./style.scss"
import {TmdbImageSizeEnum} from "helpers/Tmdb"
import {SeasonPlay} from "../../Season/Play/SeasonPlay"

type ShowPlayProps = {
  id: string,
}

export const ShowPlay = (props: ShowPlayProps) => {

  const {data} = useQuery(showGet, {
    variables: {
      id: props?.id,
    },
    fetchPolicy: "cache-and-network",
  })

  const show = data?.show
  const seasonList = data?.show?.Season


  return <div className="show-play-container">
    <div className="row">
      <div className="col-12 col-sm-6">
        <TmdbImage path={show?.tmdbOptions?.poster_path} size={TmdbImageSizeEnum.W342} />
      </div>
      <div className="col-12 col-sm-6">
        <h1>{show?.name}</h1>
        <div className="season-list">
          <Accordion>
            {seasonList && seasonList.map((season: any) => {
              return <SeasonPlay seasonId={season?.id}  key={`season_${season?.id}`}/>
            })}
          </Accordion>
        </div>
      </div>
    </div>
  </div>
}