import React from "react"

type S3OptionsProps = {
  options: any,
  onChange: (value: string) => void
}

export const S3Options = (props: S3OptionsProps) => {

  const {options, onChange} = props

  const onInputChange = (e: any) => onChange({
    ...options,
    [e.target.name]: e.target.value,
  })

  return <div className="s3-options-container">
    <div className="input-group">
      <span className="input-group-text">s3Endpoint</span>
      <input type="text" className="form-control" name="s3Endpoint" value={options?.s3Endpoint} onChange={onInputChange} />
    </div>
    <div className="input-group">
      <span className="input-group-text">s3Port</span>
      <input type="text" className="form-control" name="s3Port" value={options?.s3Port} onChange={onInputChange} />
    </div>
    <div className="input-group">
      <span className="input-group-text">s3UseSSL</span>
      <select className="form-control" name="s3UseSSL" value={options?.s3UseSSL ?? ""} onChange={(e) => onChange({
        ...options,
        [e.target.name]: (e.target.value === "1") ? true : false
      })}>
        <option value="" disabled>Choisissez une valeur</option>
        <option value="1">Oui</option>
        <option value="0">Non</option>
      </select>
    </div>
    <div className="input-group">
      <span className="input-group-text">s3AccessKey</span>
      <input type="text" className="form-control" name="s3AccessKey" value={options?.s3AccessKey} onChange={onInputChange} />
    </div>
    <div className="input-group">
      <span className="input-group-text">s3SecretKey</span>
      <input type="text" className="form-control" name="s3SecretKey" value={options?.s3SecretKey} onChange={onInputChange} />
    </div>
    <div className="input-group">
      <span className="input-group-text">bucket</span>
      <input type="text" className="form-control" name="bucket" value={options?.bucket} onChange={onInputChange} />
    </div>
  </div>
}
