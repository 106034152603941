import gql from "graphql-tag";

export default gql`
  query collectionList {
    list: collectionList {
      id
      name
      tmdbOptions
    }
  }
`