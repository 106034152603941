import React, {useState, useContext} from "react"
import {useMutation} from "@apollo/react-hooks"
import {useHistory} from "react-router-dom"

import {ImportForm} from "../Form/ImportForm"

import importAddListMutation from "graphql/Transcode/Import/mutation/importAddList"
import importListQuery from "graphql/Transcode/Import/query/importList"

import './style.scss'
import {alertContext} from "context/Alert/alert"
import {URL_BACKOFFICE_TRANSCODE_IMPORT_LIST} from "static/constant/url"

export const ImportAddList = (props: any) => {

  const history = useHistory()

  const {setError, setMessage} = useContext(alertContext)
  const [canSubmit, updateCanSubmit] = useState(false)

  const [importList, updateImportList] = useState<any[]>([{
    id: new Date().getTime(),
  }])

  const [mutate] = useMutation(importAddListMutation)

  const onChange = (updateEl: any) => {
    updateCanSubmit(true)
    updateImportList(importList.map((el) => (el.id === updateEl?.id) ? updateEl: el))
  }

  const deleteItem = (id: number) => {
    updateImportList(importList.filter((el) => (el?.id !== id)))
  }

  const handleAdd = () => {
    const lastImport: any = importList[importList.length - 1]
    updateImportList([...importList, {
      id: new Date().getTime(),
      Provider: lastImport?.Provider,
      type: "show",
      Show: lastImport?.Show,
      Season: lastImport?.Season,
    }])
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    updateCanSubmit(false)
    mutate({
      refetchQueries: [
        {
          query: importListQuery,
        }
      ],
      variables: {
        input: importList.map((el: any) => {
          const {id, Season, Show, type, ...rest} = el
          return rest;
        })
      }
    })
    .then(() => setMessage(<h1>OK</h1>, () => {
      history.push(URL_BACKOFFICE_TRANSCODE_IMPORT_LIST)
    }))
    .catch(() =>{
      setError(<div><h1>Oups :(</h1></div>)
      updateCanSubmit(true)
    })
  }

  // const isSubmitable = importList?.length > 0

  return <div className="import-add-list-container">
    <h1>Importer des Elements</h1>
    <form onSubmit={(e: any) => handleSubmit(e)}>
      <div className="list-group">
        {importList && importList.map((element: any, index: number) => {
          return <div key={`import_${element.id}_${index}`} className="list-group-item" style={{margin: 10}}>
            <div className="row">
              <div className="col-10">
                <ImportForm onChange={(el) => onChange(el)} value={element} />
              </div>
              <div className="col-2">
                <span className="icon-trash" onClick={() => deleteItem(element?.id)}></span>
              </div>
            </div>
          </div>
        })}
      </div>
      <div className="actions">
        <button type="submit" disabled={!canSubmit} className="btn btn-dark">Enregistrer</button>
        <button type="button" className="btn btn-secondary" onClick={() => handleAdd()}>Ajouter</button>
      </div>
    </form>
  </div>
}