import gql from "graphql-tag";
import MediaDetailsFragment from "../fragment/MediaDetailsFragment";

export default gql`

  ${MediaDetailsFragment}

  query mediaGetDetails($id: String!) {
    media: mediaGet(id: $id) {
      ...MediaDetailsFragment
    }
  }
`