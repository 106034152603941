import React, {useState} from "react";
import {ApolloProvider as ApolloHooksProvider} from "@apollo/react-hooks";
import {Route, Switch, BrowserRouter, Link} from 'react-router-dom';
import {Helmet} from "react-helmet";

import {getClient} from "helpers/Apollo";

import logo from "static/img/logo.png"

import {front} from './front'
import {backoffice} from './backoffice'

import {AuthWrapper, AuthTypeEnum} from "components/AuthWrapper";
import {Menu} from "components/common/Navigation/Menu/Menu"
import {URL_BACKOFFICE, URL_FRONT} from "static/constant/url";

import {menuIsOpenContext} from "context/Navigation/menuIsOpen"
import {alertContext} from "context/Alert/alert"

export const Router = (props: any) => {

  const apolloClient = getClient()

  return <div>
    <BrowserRouter>
      <ApolloHooksProvider client={apolloClient}>
        <AuthWrapper authType={AuthTypeEnum.REQUIRED} Component={() =>
          <Content />
        } />
      </ApolloHooksProvider>
    </BrowserRouter>
  </div>
}


const Content = (props: any) => {

  const [menuOpen, updateMenuOpen] = useState(false)
  const [alertContent, updateAlertContent] = useState<any>({
    message: <div />,
    duration: 2000,
    type: "",
    isVisible: false,
  })

  const handleAlert = (type: string, message: any, callback?: () => void) => {
    updateAlertContent({type, message, isVisible: true, duration: alertContent.duration})
    setTimeout(() => {
      updateAlertContent({type, message, isVisible: false, duration: alertContent.duration})
      callback && callback()
    }, alertContent.duration)
  }

  return <div className="app-content" style={{position: menuOpen ? "fixed" : "inherit"}}>
    <Helmet>
      <title>Zipflix</title>
      <meta name="description" content="Zipflix" />
      <link rel="icon" type="image/png" href="favicon.png" />
    </Helmet>
    <menuIsOpenContext.Provider value={{
      state: menuOpen,
      update: updateMenuOpen,
    }}>
      <Menu closeMenu={() => updateMenuOpen(false)} />
      <div className="logo">
        <Link to={URL_FRONT} onClick={() => updateMenuOpen(false)}><img src={logo} alt="logo" /></Link>
      </div>
      <alertContext.Provider value={{
        ...alertContent,
        setError: (message: any) => handleAlert("error", message),
        setMessage: (message: any, onClose) => handleAlert("message", message, onClose),
      }}>
        <Switch>
          <Route path={URL_BACKOFFICE} render={backoffice} />
          <Route path={URL_FRONT} render={front} />
        </Switch>
      </alertContext.Provider>
    </menuIsOpenContext.Provider>
  </div>
}
