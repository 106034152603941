import gql from "graphql-tag"
import JobFragment from "../fragment/JobFragment"

export default gql`

	${JobFragment}

	query jobListByImportId($id: String!) {
		jobList: jobListByImportId(id: $id) {
			...JobFragment
		}
	}

`