
import React from "react"
import {useQuery} from "@apollo/react-hooks"
import {useHistory} from "react-router-dom"

import {TmdbImage} from "components/common/Tmdb/TmdbImage"

import {URL_FRONT_MOVIE_PLAY} from "static/constant/url"

import collectionGet from "graphql/Media/Collection/query/collectionGet"

import "./style.scss"
import ProgressBar from "react-bootstrap/ProgressBar"
import {CollectionStatus} from "../Status/CollectionStatus"
import {TmdbImageSizeEnum, getImageUrlFromSizeAndPath} from "helpers/Tmdb"

type CollectionPlayProps = {
  id: string,
}

export const CollectionPlay = (props: CollectionPlayProps) => {

  const history = useHistory()

  const {data} = useQuery(collectionGet, {
    variables: {
      id: props?.id,
    },
    fetchPolicy: "cache-and-network",
  })

  const collection = data?.collection
  const movieList = data?.collection?.Movie

  return <div className="collection-play-container">
    <div className="row">
      <div className="col-12 col-sm-4">
        <TmdbImage path={collection?.tmdbOptions?.poster_path} size={TmdbImageSizeEnum.W342} />
      </div>
      <div className="col-12 col-sm-8">
        <div className="title">
          <h1>{collection?.name}</h1>
          <CollectionStatus status={collection?.status} />
        </div>
        <div className="movie-list row">
          {movieList?.map((movie: any) => {

            const {id, isViewable, Media} = movie
            const duration = Media?.duration
            const nbSession = Media?.Session?.length
            const hasSession = (nbSession > 0)
            const lastSession = hasSession && Media?.Session[0]

            const percent = hasSession && Number(Number(lastSession?.progress) * 100 / Number(duration))

            const imageUrl = getImageUrlFromSizeAndPath(TmdbImageSizeEnum.W154, movie?.tmdbOptions?.poster_path)

            const onClick = () => {
              if (isViewable) {
                history.push(URL_FRONT_MOVIE_PLAY.replace(`:id`, id))
              }
            }

            return <div className="movie-item col-12 col-sm-4" >
              <div className={(isViewable) ? "movie-item-content" : "movie-item-content notViewable"} onClick={onClick}>
                <div className="poster" style={{background: `url(${imageUrl})`}}>
                  {isViewable && <span className="icon-play" />}
                </div>
                {hasSession && <div className="progress-container"><ProgressBar now={percent} /></div>}
                <h2>{movie.name}</h2>
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
  </div>
}