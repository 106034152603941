import gql from "graphql-tag";
import MovieFragment from "../fragment/MovieFragment";

export default gql`

  ${MovieFragment}

  query movieGet($id: String!) {
    movie: movieGet(id: $id) {
      ...MovieFragment
    }
  }
`