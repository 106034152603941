import gql from "graphql-tag";
import MovieListFragment from "../fragment/MovieListFragment";

export default gql`

  ${MovieListFragment}

  query movieList {
    movieList {
      ...MovieListFragment
    }
  }
`