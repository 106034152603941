import gql from "graphql-tag";
import VersionFragment from "graphql/Media/Version/fragment/VersionFragment";

export default gql`

  ${VersionFragment}

  fragment EpisodeFragment on Episode {
    id
    name
    number
    tmdbOptions
    isVisible
    isViewable
    Season {
      id
      number
      Show {
        id
        name
      }
    }
    Media {
      id
      duration
      Version {
        ...VersionFragment
      }
      Session {
        id
        progress
      }
    }
  }
`