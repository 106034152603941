import gql from "graphql-tag";
import ImportDetailsFragment from "../fragment/ImportDetailsFragment";

export default gql`

  ${ImportDetailsFragment}

  subscription importUpdate($id: String!) {
    importUpdate(id: $id) {
      ...ImportDetailsFragment
    }
  }
`