import gql from "graphql-tag";

export default gql`

  fragment EpisodeSearchFragment on Episode {
    id
    name
    number
    tmdbOptions
    Season {
      id
      number
      Show {
        id
        name
      }
    }
 }
`