import React, {useRef, useEffect, useState} from "react"
import {useMutation} from "@apollo/react-hooks";

// import {SessionType} from "../../../../../common/model/Streaming/Session"
// import {SubtitleType} from "../../../../../common/model/Media/SubtitleType";

import sessionUpdate from "graphql/Streaming/Session/mutation/sessionUpdate";

import './style.scss'

type PlayerProps = {
  session: any,
  onEnd?: () => void
}

function useInterval(callback: any, delay: any) {
  const savedCallback = useRef();

  // Se souvenir de la dernière fonction de rappel.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Configurer l’intervalle.
  useEffect(() => {
    function tick() {
      // @ts-ignore
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const Player = (props: PlayerProps) => {

  const [isPlaying, updateIsPlaying] = useState(false);

  const [mutate] = useMutation(sessionUpdate)

  const {session} = props

  const {SubtitleSelected, VersionSelected, progress} = session
  const {Media} = session
  const subList = Media?.Subtitle
  const duration = Media?.duration;

  const videoRef = useRef(null)

  const computedProgress = (Number(progress) > (Number(duration) - 60)) ? 0 : progress || 0
  // console.log({progress, duration, Media})

  const updateSubOffset = () => {
    const videoElement: any = videoRef?.current
    Array.from(videoElement?.textTracks).forEach((track: any) => {
      const trackId = track?.id
      const trackUpdated = track?.updated
      if (track.mode === 'showing' && !trackUpdated) {
        const sub = subList.find((el: any) => el.id === trackId)
        const offset = (Number(sub?.offset) / 1000)
        if (offset) {
          console.log(`Setting offset to ${offset}`)
          track.updated = true;
          Array.from(track.cues).forEach((cue: any) => {
            cue.startTime += offset || 0.5;
            cue.endTime += offset || 0.5;
          });
        }
        return true;
      }
    });
  }

  const updateSession = () => {
    // console.log("Updating sesion")
    // console.log({isPlay, isPlaying, args})
    if (isPlaying) {
      // console.log({session})
      const video: any = document.getElementById('video-js')
      // console.log(video.currentTime)
      mutate({
        variables: {
          id: session?.id,
          input: {
            sessionGroup: session?.sessionGroup || "group",
            VersionSelected: session?.VersionSelected?.id,
            SubtitleSelected: session?.SubtitleSelected?.id,
            progress: Number(Number(video.currentTime).toFixed(0)),
            Media: session?.Media?.id,
            // ...rest,
          }
        }
      })
        .catch(() => {
          console.log("Unable to update")
        })

    }
  }

  const playingEventListener = () => {
    // console.log("PLAY")
    updateIsPlaying(true)
  }

  const playEventListener = () => {
    console.log("PLAY")
    // updateIsPlaying(true)
  }

  const waitingEventListener = () => {
    console.log("WAITING AND UPDATING")
    updateIsPlaying(false)
  }

  const pauseEventListener = () => {
    // console.log("PAUSE")
    updateIsPlaying(false)
  }

  const stalledEventListener = () => {
    console.log("STALLED")
    // updateIsPlaying(false)
  }

  const loadedDataListener = () => {
    // console.log("LOADED")
    updateSubOffset();
  }

  const endDataListener = () => {
    // console.log("LOADED")
    // updateSubOffset();
    props?.onEnd()
  }

  // useEffect(() => {
  //   // const video = document.getElementById('video-js')
  //   const video = videoRef.current
  //   // video?.addEventListener("loadeddata", loadedDataListener)
  //   // video?.addEventListener("playing", playEventListener)
  //   // video?.addEventListener("pause", pauseEventListener)
  //   video?.addEventListener("ended", endDataListener)
  //   return () => {
  //     // video?.removeEventListener("loadeddata", loadedDataListener)
  //     // video?.removeEventListener("playing", playEventListener)
  //     // video?.removeEventListener("pause", playEventListener)
  //     video?.removeEventListener("ended", endDataListener)
  //   }
  // }, [])

  useInterval(() => updateSession(), 4000)

  const streamUrl = `${process.env.REACT_APP_API_ENDPOINT}/stream/${VersionSelected?.id}#t=${computedProgress || 0}`

  return <div className="player-container">
    <div>
      <video key={session?.id} onEnded={endDataListener} onStalled={stalledEventListener} onWaiting={waitingEventListener} onPause={pauseEventListener} onLoadedData={loadedDataListener} onPlay={playEventListener} onPlaying={playingEventListener} ref={videoRef} className="video-js" id="video-js" autoPlay controls >
        <source src={streamUrl} type="video/mp4"></source>
        {subList?.map((sub: any) => <track key={`source_${sub.id}`} id={sub.id} src={`${process.env.REACT_APP_API_ENDPOINT}/subtitle/${sub?.id}`} lang={sub.language} label={sub.language} default={!!(SubtitleSelected?.id === sub.id)}></track>)}
      </video>
    </div>
  </div>
}

