import React from "react"
import {useQuery, useMutation, useSubscription} from "@apollo/react-hooks"
import ProgressBar from "react-bootstrap/ProgressBar"

import MUTATION_JOB_START from "graphql/Transcode/Job/mutation/jobStart"
import MUTATION_JOB_STOP from "graphql/Transcode/Job/mutation/jobStop"
import JOB_SUBSCRIPTION from "graphql/Transcode/Job/subscription/jobUpdate"
import JOB_LIST_QUERY from "graphql/Transcode/Job/query/jobList"

import "./style.scss"

type JobListProps = {
  importId: string
}

export const JobList = (props: JobListProps) => {

  const {data} = useQuery(JOB_LIST_QUERY, {
    skip: !props?.importId,
    variables: {
      id: props?.importId,
    }
  })
  const jobList = data?.jobList

  const jobStartMutationHook = useMutation(MUTATION_JOB_START)
  const jobStopMutationHook = useMutation(MUTATION_JOB_STOP)

  useSubscription(
    JOB_SUBSCRIPTION,
  );


  const startJob = (jobId: string) => {
    jobStartMutationHook[0]({
      variables: {
        jobId: jobId
      }
    })
      .then(() => console.log("OK"))
      .catch(() => console.log("NOPE"))
  }

  const stopJob = (jobId: string) => {
    jobStopMutationHook[0]({
      variables: {
        jobId: jobId
      }
    })
      .then(() => console.log("OK"))
      .catch(() => console.log("NOPE"))
  }

  const renderStatus = (status: string) => {
    switch (status) {
      default:
      case "INITIAL": {
        return <span className="badge badge-info">INITIAL</span>
      }
      case "ERROR": {
        return <span className="badge badge-danger">ERROR</span>
      }
      case "DONE": {
        return <span className="badge badge-success">DONE</span>
      }
      case "RUNNING": {
        return <span className="badge badge-warning">RUNNING <span className="icon-spinner"></span></span>
      }
    }
  }


  return <div>
    <h1>Job List</h1>
    <div className="joblist-container">
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Element</th>
            <th>Type</th>
            <th>Langue</th>
            <th>Status</th>
            <th>Progression</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {jobList?.map((job: any) => {
            const isMovie = !!job?.Import?.Media?.Movie
            const element = isMovie ? job?.Import?.Media?.Movie?.name : `${job?.Import?.Media?.Episode?.Season?.Show?.name} - Season ${job?.Import?.Media?.Episode?.Season?.number} - Episode ${job?.Import?.Media?.Episode?.number} - ${job?.Import?.Media?.Episode?.name}`
            const isStartable = job?.status === "INITIAL" || job?.status === "ERROR" || job?.status === "DONE"
            const isRunning = job?.status === "RUNNING"

            return <tr key={`job_${job?.id}`} className="">
              <td>{element}</td>
              <td>{job?.type}</td>
              <td>{job?.language}</td>
              <td>{renderStatus(job?.status)}</td>
              <td><ProgressBar now={job?.progress} label={`${job?.progress}%`} /></td>
              <td>
                {isStartable && <button className="btn btn-info" onClick={() => startJob(job?.id)}>Start</button>}
                {isRunning && <button className="btn btn-danger" onClick={() => stopJob(job?.id)}>Stop</button>}
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
  </div>

}