import React, {useState, useEffect} from "react"

import {useQuery} from "@apollo/react-hooks"
import {FileSearch} from "components/backoffice/Storage/File/FileSearch"

import QUERY_SHOW from "graphql/Media/Show/query/showList"
import QUERY_MOVIE_LIST from "graphql/Media/Movie/query/movieList"
import providerListQuery from "graphql/Storage/Provider/query/providerList"

export type ImportFormProps = {
  onChange: (payload: any) => void
  value: any,
}

export const ImportForm = (props: ImportFormProps) => {

  // const {type, provider,}
  // console.log({type: props?.value?.type})
  const [type, updateType] = useState(props?.value?.type || "")
  const [provider, updateProvider] = useState(props?.value?.Provider || "")
  const [show, updateShow] = useState(props?.value?.Show || "")
  const [season, updateSeason] = useState(props?.value?.Season || "")
  const [episode, updateEpisode] = useState("")
  const [movie, updateMovie] = useState("")
  const [file, updateFile] = useState(null)

  const {data: {showList = []} = {}} = useQuery(QUERY_SHOW)
  const {data: {providerList = []} = {}} = useQuery(providerListQuery)
  const {data: {movieList = []} = {}} = useQuery(QUERY_MOVIE_LIST)

  const isShow = type === "show"
  const isMovie = type === "movie"

  const showSelected = showList?.find((el: any) => el.id === show)
  const seasonSelected = showSelected?.Season?.find((el: any) => el.id === season)

  // const isFormValid = isShow ? (!!episode && !!file) : false

  const onChange = props?.onChange

  const providerListFiltered = providerList?.filter((el: any) => el.target === "MEDIA")

  useEffect(() => {
    if (providerListFiltered?.length === 1 && provider === "") {
      updateProvider(providerListFiltered[0].id)
    }
    // props?.value?.Provider && updateProvider(props?.value?.Provider)
    // console.log({vlue: props?.value})
  }, [providerListFiltered])

  useEffect(() => {
    onChange({
      ...props?.value,
      File: file?.id,
      Episode: episode,
      Provider: provider,
      Show: (show === "") ? null : show,
      Season: (season === "") ? null : season,
      Movie: (movie === "") ? null : movie,
    })
  }, [episode, file, provider, provider, movie])

  return <div>
    <div className="row">
      <div className="media col-12">
        <div className="input-group">
          <span className="input-group-text">Provider</span>
          <select name="provider" className="form-control" value={provider} onChange={e => updateProvider(e.target.value)}>
            <option value="" disabled>Choisissez un provider</option>
            {providerListFiltered
              ?.map((providerItem: any) => {
                return <option key={`provider${providerItem?.id}`} value={providerItem?.id}>{providerItem?.name}</option>
              })}
          </select>
        </div>
      </div>
      <div className="media col-12 col-sm-6">
        <div className="input-group">
          <span className="input-group-text">Type</span>
          <select name="type" className="form-control" value={type} onChange={e => updateType(e.target.value)}>
            <option value="" disabled>Choisissez un type</option>
            <option value="movie">Movie</option>
            <option value="show">Show</option>
          </select>
        </div>
        {isMovie && <div>
          <div className="input-group">
            <span className="input-group-text">Movie</span>
            <select name="movie" className="form-control" value={movie} onChange={e => updateMovie(e.target.value)}>
              <option value="" disabled>Choisissez un movie</option>
              {movieList
                ?.filter((el: any) => !el.isViewable)
                ?.map((movie: any) => {
                  return <option key={`movie${movie?.id}`} value={movie?.id}>{movie?.name}</option>
                })}
            </select>
          </div>
        </div>}
        {isShow && <div>
          <div className="input-group">
            <span className="input-group-text">Show</span>
            <select name="show" className="form-control" value={show} onChange={e => updateShow(e.target.value)}>
              <option value="" disabled>Choisissez un show</option>
              {showList?.map((show: any) => {
                return <option key={`show_${show?.id}`} value={show?.id}>{show?.name}</option>
              })}
            </select>
          </div>
          {show !== "" && <div className="input-group">
            <span className="input-group-text">Season</span>
            <select name="season" className="form-control" value={season} onChange={e => updateSeason(e.target.value)}>
              <option value="" disabled>Choisissez une saison</option>
              {showSelected?.Season
                ?.filter((el: any) => (el.status !== "COMPLETE"))
                ?.map((season: any) => {
                  return <option key={`season_${season?.id}`} value={season?.id}>{season?.number}</option>
                })}
            </select>
          </div>}
          {season !== "" && <div className="input-group">
            <span className="input-group-text">Episode</span>
            <select name="episode" className="form-control" value={episode} onChange={e => updateEpisode(e.target.value)}>
              <option value="" disabled>Choisissez un épisode</option>
              {seasonSelected?.Episode
                ?.filter((el: any) => !el.isViewable)
                ?.map((episode: any) => {
                  return <option key={`episode${episode?.id}`} value={episode?.id}>{`${episode?.number} - ${episode?.name}`}</option>
                })}
            </select>
          </div>}
        </div>}
      </div>
      <div className="file col-12 col-sm-6">
        <FileSearch value={file} onSelect={(v: any) => updateFile(v)} />
      </div>
    </div>
  </div>
}