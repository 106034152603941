import React from "react";
import BootstrapModal from "react-bootstrap/Modal"

import "./style.scss"

export const Modal = (props: any) => {

  const handleClose = () => {
    const {onClose} = props;
    onClose && onClose();
  }

  const {display, children, onClose, title, size = "lg", centered = true, footer} = props;

  const displayHeader = onClose || title

  return (
    <BootstrapModal show={display} onHide={handleClose} size={size} aria-labelledby="contained-modal-title-vcenter" centered={centered}>
      {displayHeader && <BootstrapModal.Header closeButton={!!onClose}>
        {title}
      </BootstrapModal.Header>}
      <BootstrapModal.Body>
        {children}
      </BootstrapModal.Body>
      {footer && <BootstrapModal.Footer>
        {footer}
      </BootstrapModal.Footer>}
    </BootstrapModal>
  )
}