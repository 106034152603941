import gql from "graphql-tag";

export default gql`
  fragment ImportDetailsFragment on Import {
    id
    Media {
      id
      Version {
        id
        File {
          id
          name
          transferProgress
        }
      }
    }
    File {
      id
      name
      transferProgress
    }
    hasError
    isRunning
    status
  }
`