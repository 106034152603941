import gql from "graphql-tag";
import CollectionFragment from "../fragment/CollectionFragment";

export default gql`

  ${CollectionFragment}

  query collectionGet($id: String!) {
    collection: collectionGet(id: $id) {
      ...CollectionFragment
    }
  }
`