import gql from "graphql-tag";
import ProviderFragment from "../fragment/ProviderFragment";

export default gql`

  ${ProviderFragment}

  mutation providerEdit($id: String!, $input: StorageProviderInput!) {
    provider: providerEdit(id: $id, input: $input) {
      ...ProviderFragment
    }
  }
`