import React from "react"


import './style.scss'

type StepperProps = {
  stepList: {
    value: string,
    content: () => {},
  }[],
  activeValue: string,
}

export const Stepper = (props: StepperProps) => {

  const {stepList, activeValue} = props
  const activeNode = stepList.find(el => el.value === activeValue)

  return <div className="stepper-container col-12">
    <div className="step-container col-3">
      {stepList?.map((step) => {
        const {value} = step
        return <div className="step" key={`step_${value}`}>
          <div className="before"></div>
          <div className={(activeValue === value) ? "label active" : "label"}>
            <span className="icon-circle"></span>
            <span>{value}</span>
          </div>
          <div className="after"></div>
        </div>
      })}
    </div>
    <div className="content-container col-9">
      {activeNode && activeNode.content()}
    </div>
  </div>
}