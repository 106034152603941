import React, {useContext} from "react"
import {useHistory} from "react-router-dom"

import {URL_FRONT, URL_BACKOFFICE_TRANSCODE_IMPORT_LIST, URL_BACKOFFICE_MEDIA_COLLECTION_ADD, URL_BACKOFFICE_MEDIA_MOVIE_ADD, URL_BACKOFFICE_MEDIA_SHOW_ADD, URL_BACKOFFICE_TRANSCODE_IMPORT_ADD_LIST, URL_BACKOFFICE_STORAGE_PROVIDER_ADD, URL_BACKOFFICE_STORAGE_PROVIDER_LIST, URL_BACKOFFICE_STORAGE_PROVIDER_SCAN, URL_FRONT_SEARCH, URL_BACKOFFICE_USER_ADD, URL_BACKOFFICE_USER_LIST} from "static/constant/url"


import "./style.scss"
import {userContext} from "context/User"
import {menuIsOpenContext} from "context/Navigation/menuIsOpen"
import {useQuery} from "@apollo/react-hooks"
import importCount from "graphql/Transcode/Import/query/importCount"

export const Menu = (props: any) => {

  const {data} = useQuery(importCount)

  const history = useHistory()
  const user = useContext(userContext)
  const {state, update} = useContext(menuIsOpenContext)

  const onLinkClick = (URL: string) => {
    props?.closeMenu()
    history?.push(URL)
  }
  const {isAdmin} = user || {}

  const nbImport = data?.importCount?.length

  return <div className="menu-container">
    <div>
      <div className="menu-button">
        <span className="icon-bars" onClick={() => update(!state)}></span>
      </div>
      <div className={state ? "banner visible" : "banner"}>
        <div className="content">
          <ul>
            <li><h1><span onClick={() => onLinkClick(URL_FRONT)}>Accueil</span></h1></li>
            <li><h1><span onClick={() => onLinkClick(URL_FRONT_SEARCH)}>Rechercher</span></h1></li>
          </ul>
          {isAdmin && <ul>
            <li><h1>Backoffice</h1></li>
            <li><h2>Import</h2></li>
            <li><h3><span onClick={() => onLinkClick(URL_BACKOFFICE_TRANSCODE_IMPORT_ADD_LIST)}>Ajouter des imports</span></h3></li>
            <li><h3><span onClick={() => onLinkClick(URL_BACKOFFICE_TRANSCODE_IMPORT_LIST)}>Lister les Imports {(nbImport !== 0) && <span className="badge badge-light">{nbImport}</span>}</span></h3></li>
            <li><h2>Media</h2></li>
            <li><h3><span onClick={() => onLinkClick(URL_BACKOFFICE_MEDIA_SHOW_ADD)}>Ajouter un show</span></h3></li>
            <li><h3><span onClick={() => onLinkClick(URL_BACKOFFICE_MEDIA_MOVIE_ADD)}>Ajouter un film</span></h3></li>
            <li><h3><span onClick={() => onLinkClick(URL_BACKOFFICE_MEDIA_COLLECTION_ADD)}>Ajouter une collection</span></h3></li>
            <li><h2>Storage</h2></li>
            <li><h3><span onClick={() => onLinkClick(URL_BACKOFFICE_STORAGE_PROVIDER_ADD)}>Ajouter un Provider</span></h3></li>
            <li><h3><span onClick={() => onLinkClick(URL_BACKOFFICE_STORAGE_PROVIDER_LIST)}>Lister les Provider</span></h3></li>
            <li><h3><span onClick={() => onLinkClick(URL_BACKOFFICE_STORAGE_PROVIDER_SCAN)}>Scanner les provider</span></h3></li>
            <li><h2>Utilisateur</h2></li>
            <li><h3><span onClick={() => onLinkClick(URL_BACKOFFICE_USER_ADD)}>Ajouter un Utilisateur</span></h3></li>
            <li><h3><span onClick={() => onLinkClick(URL_BACKOFFICE_USER_LIST)}>Lister les Utilisateurs</span></h3></li>
          </ul>}
          <div className="footer-container">
            <div className="footer">
              {user?.email}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}