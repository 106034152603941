import React from "react"
import {Link} from "react-router-dom"
import {URL_FRONT_EPISODE_PLAY, URL_FRONT_MOVIE_PLAY} from "static/constant/url"
import {getImageUrlFromSizeAndPath, TmdbImageSizeEnum} from "helpers/Tmdb"

import "./style.scss"

type MediaWatchNextProps = {
  media: any,
}

export const MediaWatchNext = (props: MediaWatchNextProps) => {

  const media = props?.media

  const nextMediaId = media?.id
  const isEpisode = !!media?.Episode

  const label = isEpisode ? `Episode suivant: ${media?.Episode?.name}` : `Film suivant : ${media?.Movie?.name}`


  const renderEpisode = () => {
    const {tmdbOptions} = media?.Episode
    const url = getImageUrlFromSizeAndPath(TmdbImageSizeEnum.W154, tmdbOptions?.still_path)
    return <Link to={URL_FRONT_EPISODE_PLAY.replace(':id', media?.Episode?.id)}>
      <div style={{background: `url(${url})`}} className="still_path"></div>
      <div><h2>{label}</h2></div>
    </Link>
  }

  return <div className="watchnext-container">
    {nextMediaId && <div>
      <h1>Voir ensuite</h1>
      <div className="">
        {isEpisode && renderEpisode()}
        {!isEpisode && <Link to={URL_FRONT_MOVIE_PLAY.replace(':id', media?.Movie?.id)}>{label}</Link>}
      </div>
    </div>}
  </div>
}