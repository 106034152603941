import React from "react"
import {Route, Switch} from "react-router-dom"

import {URL_BACKOFFICE_TRANSCODE_IMPORT_ADD_LIST, URL_BACKOFFICE_TRANSCODE_IMPORT_ADD, URL_BACKOFFICE_TRANSCODE_IMPORT_EDIT, URL_BACKOFFICE_MEDIA_SHOW_ADD, URL_BACKOFFICE_MEDIA_SHOW_EDIT, URL_BACKOFFICE_MEDIA_EPISODE_ADD, URL_BACKOFFICE_MEDIA_EPISODE_EDIT, URL_BACKOFFICE_TRANSCODE_JOB_STATUS, URL_BACKOFFICE_TRANSCODE_IMPORT_LIST, URL_BACKOFFICE_MEDIA_COLLECTION_ADD, URL_BACKOFFICE_STORAGE_PROVIDER_LIST, URL_BACKOFFICE_STORAGE_PROVIDER_ADD, URL_BACKOFFICE_STORAGE_PROVIDER_SCAN, URL_BACKOFFICE_MEDIA_MOVIE_ADD, URL_BACKOFFICE_USER_LIST, URL_BACKOFFICE_USER_ADD} from "static/constant/url"

import {ShowEditRoute} from "./Media/ShowEditRoute"
import {MovieEditRoute} from "./Media/MovieEditRoute"
import {EpisodeEditRoute} from "./Media/EpisodeEditRoute"
import {ImportEditRoute} from "./Transcode/ImportEditRoute"
import {JobStatusRoute} from "./Transcode/JobStatus"
import {ImportListRoute} from "./Transcode/ImportListRoute"
import {ImportAddListRoute} from "./Transcode/ImportAddListRoute"
import {CollectionAddRoute} from "./Media/CollectionAddRoute"
import {BreadCrumb} from "components/backoffice/Navigation/BreadCrumb/BreadCrumb"
import {ProviderListRoute} from "./Storage/ProviderListRoute"
import {Alert} from "components/common/Navigation/Alert/Alert"
import {ProviderAddRoute} from "./Storage/ProviderAddRoute"
import {DefaultRoute} from "./DefaultRoute"
import {ProviderScanRoute} from "./Storage/ProviderScanRoute"
import {UserAddRoute} from "./User/Add/UserAddRoute"
import {UserListRoute} from "./User/List/UserListRoute"

export const backoffice = (props: any) => {

  return <div className="backoffice-container">
    <div className="container">
      <BreadCrumb />
      <Alert />

      <Switch>
        <Route path={URL_BACKOFFICE_MEDIA_SHOW_ADD} component={ShowEditRoute} exact />
        <Route path={URL_BACKOFFICE_MEDIA_SHOW_EDIT} component={ShowEditRoute} exact />

        <Route path={URL_BACKOFFICE_MEDIA_EPISODE_ADD} component={EpisodeEditRoute} exact />
        <Route path={URL_BACKOFFICE_MEDIA_EPISODE_EDIT} component={EpisodeEditRoute} exact />

        <Route path={URL_BACKOFFICE_MEDIA_COLLECTION_ADD} component={CollectionAddRoute} exact />

        <Route path={URL_BACKOFFICE_MEDIA_MOVIE_ADD} component={MovieEditRoute} exact />

        <Route path={URL_BACKOFFICE_TRANSCODE_IMPORT_ADD} component={ImportEditRoute} exact />
        <Route path={URL_BACKOFFICE_TRANSCODE_IMPORT_ADD_LIST} component={ImportAddListRoute} exact />
        <Route path={URL_BACKOFFICE_TRANSCODE_IMPORT_EDIT} component={ImportEditRoute} exact />
        <Route path={URL_BACKOFFICE_TRANSCODE_IMPORT_LIST} component={ImportListRoute} exact />

        <Route path={URL_BACKOFFICE_STORAGE_PROVIDER_LIST} component={ProviderListRoute} exact />
        <Route path={URL_BACKOFFICE_STORAGE_PROVIDER_ADD} component={ProviderAddRoute} exact />
        <Route path={URL_BACKOFFICE_STORAGE_PROVIDER_SCAN} component={ProviderScanRoute} exact />

        <Route path={URL_BACKOFFICE_USER_LIST} component={UserListRoute} exact />
        <Route path={URL_BACKOFFICE_USER_ADD} component={UserAddRoute} exact />

        <Route path={URL_BACKOFFICE_TRANSCODE_JOB_STATUS} component={JobStatusRoute} exact />
        {/* <Route path={URL_BACKOFFICE_TRANSCODE_JOB_LIST} component={JobListRoute} exact /> */}


        <Route path={`*`} component={DefaultRoute} />
      </Switch>
    </div>
  </div>
}