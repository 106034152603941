import gql from "graphql-tag";
import VersionFragment from "graphql/Media/Version/fragment/VersionFragment";

export default gql`

  ${VersionFragment}

  fragment MovieFragment on Movie {
    id
    name
    tmdbOptions
    isViewable
    Media {
      id
      duration
      Version {
        ...VersionFragment
      }
      Session {
        id
        progress
      }
    }
  }
`