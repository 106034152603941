import gql from "graphql-tag";
import MovieFragment from "../fragment/MovieFragment";

export default gql`

  ${MovieFragment}

  mutation movieAdd($input: MovieInput!) {
    movieAdd(input: $input) {
      ...MovieFragment
    }
  }
`