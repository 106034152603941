import React from "react"


export const CollectionStatus = (props: any) => {

  const {status} = props

  switch(status) {
    case "UNAVAILABLE": {
      return <span className="badge badge-danger">INDISPONIBLE</span>
    }
    case "INCOMPLETE": {
      return <span className="badge badge-warning">INCOMPLETE</span>
    }
    default: {
      return <span/>
    }
  }

}