import React from "react"
import {useQuery} from "@apollo/react-hooks"
import {Link} from "react-router-dom"
import {URL_BACKOFFICE_TRANSCODE_IMPORT_ADD_LIST} from "static/constant/url"

import IMPORT_LIST_QUERY from "graphql/Transcode/Import/query/importList"

import "./style.scss"
import {ImportDetails} from "../Details/ImportDetails"

export const ImportList = (props: any) => {

  const {data} = useQuery(IMPORT_LIST_QUERY, {
    fetchPolicy: "cache-and-network",
  })
  const importList = data?.importList
  // const importStartMutationHook = useMutation(MUTATION_JOB_STOP)

  const hasElements = importList?.length > 0

  return <div className="import-list-container">
    <h1>Import List</h1>
    {hasElements && <div className="">
      {importList?.map((importItem: any) => {
        return <ImportDetails importId={importItem?.id} key={importItem?.id} />
      })}
    </div>}
    {!hasElements && <div className="alert alert-dark">
      Nothing :)
    </div>}
    <div className="actions">
      <Link to={URL_BACKOFFICE_TRANSCODE_IMPORT_ADD_LIST}><button className="btn btn-dark">Ajouter</button></Link>
    </div>
  </div>

}