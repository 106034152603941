import gql from "graphql-tag";
import MediaFragment from "../fragment/MediaFragment";

export default gql`

  ${MediaFragment}

  query mediaGet($id: String!) {
    media: mediaGet(id: $id) {
      ...MediaFragment
    }
  }
`