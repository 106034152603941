import gql from "graphql-tag";

export default gql`

  fragment MovieSearchFragment on Movie {
    id
    name
    tmdbOptions
    isViewable
  }
`