import {Cookies} from 'react-cookie';
// import {COOKIE_KEY} from "app/static/constant/user";

const cookie = new Cookies()

const COOKIE_KEY = "zipflix"

export const saveLoginCookie = (value:any ) => {
  console.log("SAving cookie")
  cookie.set(COOKIE_KEY, JSON.stringify(value));
}

export const getLoginCookie =  () => {
  return cookie.get(COOKIE_KEY);
}

export const clearLoginCookie = () => {
  cookie.set(COOKIE_KEY, JSON.stringify({}));
  return cookie.remove(COOKIE_KEY)
}

// export const Cookie = {
//   save : (token:any, expires_in:any, refresh_token:any) => {
//     let authInfo = {
//       token,
//       refresh_token,
//       expiration: Math.floor(Date.now() / 1000) + expires_in
//     }
//     cookie.set(COOKIE_KEY, JSON.stringify(authInfo));
//   },
//   flush: () => {
//     cookie.set(COOKIE_KEY, JSON.stringify({}));
//   },
//   isValid : () => {
//     const now = Math.floor(Date.now() / 1000);
//     let data = this.get();
//     let {token, expiration} = (data) ? data: {};
//     return (token && (expiration > now));
//   },
//   get : () => {
//     let data = cookie.get(COOKIE_KEY);
//     return (data) ? data : {};
//   }
// }
