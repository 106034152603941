import React, {useEffect} from "react";
import {handleLoginByToken} from "helpers/User";
// import {useQuery} from "@apollo/react-hooks"

import {userContext} from "../context/User"


// import {Modal} from "app/components/common/Navigation/Modal/Modal";
// import {LoginForm} from "app/containers/backoffice/Login/LoginForm";
// import {handleLoginByToken} from "app/helpers/User"

import {useQuery} from "@apollo/react-hooks";
import {Modal} from "./common/Navigation/Modal/Modal";
import {LoginForm} from "./common/User/Login/LoginForm";

import userProfileQuery from "graphql/User/query/userProfile"

export enum AuthTypeEnum {
  OPTIONAL= "OPTIONAL",
  REQUIRED= "REQUIRED",
}

export const AuthWrapper = (props: any) => {

  const {authType, Component, ...routerProps} = props

  const {data, loading} = useQuery(userProfileQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const {userProfile} = data || {}
  const isAuthenticated = !!userProfile

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id')
    const token = urlParams.get('token')
    if (token && id) {
      console.log("Trying to login via token")
      handleLoginByToken(id, token)
    }
  }, [userProfile])

  switch (authType) {

    case AuthTypeEnum.REQUIRED: {
      return (isAuthenticated) ? <userContext.Provider value={userProfile}><Component {...routerProps} /></userContext.Provider> : <div>
        <Modal display={loading} size={"xs"}>
          <span className="icon-spinner" style={{fontSize: 72}}></span>
        </Modal>
        <Modal display={!loading}>
          <LoginForm />
        </Modal>
        <Component
          {...routerProps}
        />
      </div>
    }

    // case "REQUIRED":
    case AuthTypeEnum.OPTIONAL: {
      return <userContext.Provider value={userProfile}>
        <Component
          {...routerProps}
        />
      </userContext.Provider>

    }
    default: {
      return <Modal display={true} title="Connexion">
        <LoginForm />
      </Modal>
    }
  }
}