import React, {useState} from "react"
import {useQuery} from "@apollo/react-hooks"

import QUERY_SEARCH from "graphql/Storage/File/query/fileSearch"

let timeout: any = null;

export const FileSearch = (props: any) => {

  const [search, updateSearch] = useState("")
  const [searchResults, updateSearchResult] = useState(null)
  const [resultSelectedIndex, updateResultSelectedIndex] = useState(-1)
  // const [resultSelected, updateResultSelected] = useState(null)

  const {refetch} = useQuery(QUERY_SEARCH, {
    skip: true,
  })

  const onResultFocus = (index: number) => {
    updateResultSelectedIndex(index)
  }

  const onResultSelected = (index: number) => {
    // updateResultSelected(searchResults[index])
    updateSearch(searchResults[index]?.name)
    updateSearchResult(null);
    updateResultSelectedIndex(-1);
    props?.onSelect(searchResults[index])
  }

  const onReset = () => {
    // updateResultSelected(null)
    updateSearch("")
    updateSearchResult(null);
    updateResultSelectedIndex(-1);
    props?.onSelect("")
  }

  const runSearch = async (text: string) => {
    const response = await refetch({
      text,
    })
    updateSearchResult(response?.data?.search)

  }

  const onChange = (e: any) => {
    const text = e.target.value
    updateSearch(text)
    if (timeout) {
      clearTimeout(timeout)
    }
    if (text?.length > 3) {
      timeout = setTimeout(() => {
        runSearch(text)
      }, 800)
    }
  }

  const onKeyDown = (event: any) => {
    switch (event?.key) {
      case "ArrowDown": {
        if (resultSelectedIndex === -1) {
          updateResultSelectedIndex(0);
        } else {
          // @ts-ignore
          if (resultSelectedIndex < (searchResults?.length - 1)) {
            updateResultSelectedIndex(resultSelectedIndex + 1)
          }
        }
        break;
      }

      case "ArrowUp": {
        // @ts-ignore
        if (resultSelectedIndex && resultSelectedIndex > 0) {
          updateResultSelectedIndex(resultSelectedIndex - 1)
        }
        break;
      }

      case "Enter": {
        if (resultSelectedIndex !== -1 && searchResults) {
          onResultSelected(resultSelectedIndex)
        }
        break;
      }
    }
    return event
  }

  // const hasResultSelected = resultSelected !== null
  const hasResultSelected = !!props?.value
  // console.log({value: props?.value?.name})
  const searchValue = (hasResultSelected) ? props?.value?.name : search

  return <div className="row">
    <div className="input-group">
      <input value={searchValue} disabled={hasResultSelected} className="form-control" placeholder="Rechercher un fichier" onChange={onChange} onKeyPress={onKeyDown} />
      {hasResultSelected && <span className="input-group-text"><span className="icon-trash" onClick={onReset} /></span>}
    </div>
    <div className="result">
      <ul className='list-group'>
        {searchResults?.map((result: any, index: number) => {
          return <li key={`result_${index}`} className={index === resultSelectedIndex ? "list-group-item active" : "list-group-item"} onMouseEnter={() => onResultFocus(index)} onClick={() => onResultSelected(index)}>{result?.name}</li>
        })}
      </ul>
    </div>
  </div>
}