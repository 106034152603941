import React from "react"

import {RouteComponentProps} from "react-router-dom"
import {MoviePlay} from "components/front/Media/Movie/Play/MoviePlay"

export const MoviePlayRoute = (props: RouteComponentProps) => {

  const {match} = props
  const {params} = match
  // @ts-ignore
  const movieId = params?.id

  return <div className="episode-play-route" >
    <MoviePlay id={movieId} />
  </div>
}