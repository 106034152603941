import gql from "graphql-tag";
import SessionFragment from "../fragment/SessionFragment";

export default gql`

  ${SessionFragment}

  mutation sessionUpdate($id: String!, $input: SessionInput!) {
    session: sessionUpdate(id: $id, input: $input) {
      ...SessionFragment
    }
  }

`