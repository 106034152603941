import gql from "graphql-tag"

export default gql`
  mutation importAdd($File: String!, $Movie: String, $Episode: String) {
    importAdd(input: {
      File: $File
      Movie: $Movie
      Episode: $Episode
    }) {
      id
    }
  }
`