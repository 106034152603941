import React, {useState} from "react"
import {useQuery, useMutation} from "@apollo/react-hooks"

import {TmdbImage} from "components/common/Tmdb/TmdbImage"

import showListQuery from 'graphql/Media/Show/query/showList'
import episodeSearchQuery from "graphql/Media/Tmdb/query/searchEpisode"
import episodeAddMutatipon from "graphql/Media/Episode/mutation/episodeAdd"
import {TmdbImageSizeEnum} from "helpers/Tmdb"

export const EpisodeEdit = (props: any) => {

  const [showSelected, udpateShowSelected] = useState("")
  const [seasonSelected, udpateSeasonSelected] = useState("")
  const [episodeNumber, updateEpisodeNumber] = useState("")

  const {data: {showList = []} = {}} = useQuery(showListQuery)

  const episodeResponse = useQuery(episodeSearchQuery, {
    skip: !!!episodeNumber,
    variables: {
      Show: showSelected,
      episode: Number(episodeNumber),
      season: Number(seasonSelected),
    }
  })

  const [mutate] = useMutation(episodeAddMutatipon)

  const episode = episodeResponse?.data?.search
  const statusCode = episode?.status_code;
  const notFound = !!statusCode

  const Show = showList.find(el => el.id === showSelected)
  const SeasonList = Show?.Season

  const seasonId = SeasonList?.find((el:any) => el.number === Number(seasonSelected))?.id

  const isFormValid = episode && !notFound

  const onSubmit = (e: any) => {
    e.preventDefault()
    mutate({
      variables: {
        tmdbOptions: episode,
        name: episode?.name,
        number: Number(episodeNumber),
        Season: seasonId
      }
    })
    .then(() => console.log("OK"))
  }


  // console.log({SeasonList})


  return <div>
    <h1>Episode</h1>
    <div className="row">
      <div className="col-sm-6">
        <form onSubmit={onSubmit}>
          <div className="input-group">
            <select value={showSelected} className="form-control" onChange={e => udpateShowSelected(e.target.value)}>
              <option value={""} disabled>Selectionner un show</option>
              {showList?.map((show: any) => {
                return <option key={`show_${show?.id}`} value={show?.id}>{show?.name}</option>
              })}
            </select>
          </div>
          {showSelected && <div className="input-group">
            <select className="form-control" value={seasonSelected} onChange={e => udpateSeasonSelected(e.target.value)}>
              <option value={""} disabled>Selectionner une saison</option>
              {SeasonList?.map((season: any) => {
                return <option key={`show_${season?.id}`} value={season?.number}>{season?.number}</option>
              })}
            </select>
          </div>}
          {seasonSelected && <div className="input-group">
            <input type="number" className="form-control" placeholder="Numéro de l'épisode" value={episodeNumber} onChange={e => updateEpisodeNumber(e.target.value)} />
          </div>}
          <div>
            {isFormValid && <button type="submit">Enregister</button>}
          </div>
        </form>
      </div>
      <div className="col-sm-6">
        {!notFound && <div>
          <div>
            <h1>{episode?.name}</h1>
          </div>
          <div>
            <TmdbImage path={
              // @ts-ignore
              episode?.still_path
            } size={TmdbImageSizeEnum.W500} />
          </div>
        </div>}
        {notFound && <div className="alert alert-danger">Not Found</div>}
      </div>
    </div>
  </div>
}