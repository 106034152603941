import React, {useState, useRef} from "react"
import searchQuery from "graphql/Media/Search/query/search"
import {useQuery} from "@apollo/react-hooks"
// let timeout: any = null

import './style.scss'
import {MediaSlider, MediaTypeEnum} from "../Media/Slider"

export const Search = () => {

  const SEARCH_TEXT_MIN_LENGTH = 2

  const timeoutRef = useRef(null)

  // const {setMessage, setError} = useContext(alertContext)
  const [text, updateText] = useState("")
  const [loading, updateLoading] = useState(false)
  const [resultList, updateResultList] = useState<any[]>(null)
  const {refetch} = useQuery(searchQuery, {
    skip: true,
    fetchPolicy: "no-cache",
  })

  const trySearch = (value: string) => {
    if (value?.length > SEARCH_TEXT_MIN_LENGTH) {
      timeoutRef.current = setTimeout(async () => {
        updateLoading(true)
        const response = await refetch({
          text: value,
        })
        updateResultList(response?.data?.search)
        updateLoading(false)
      }, 800)
    }
  }

  const onChange = (value: string) => {
    updateText(value)
    clearTimeout(timeoutRef.current)
    trySearch(value)
  }

  const onKeyPress = (e: any) => {
    // if(e.key ==="Enter") {
    //   trySearch(text)
    // }
  }

  const canSubmit = text?.length > SEARCH_TEXT_MIN_LENGTH

  const episodeList = resultList?.filter(el => el.__typename === "Episode")
  const collectionList = resultList?.filter(el => el.__typename === "Collection")
  const movieList = resultList?.filter(el => el.__typename === "Movie")
  const showList = resultList?.filter(el => el.__typename === "Show")

  const hasCollection = (collectionList?.length > 0)
  const hasMovie = (movieList?.length > 0)
  const hasShow = (showList?.length > 0)
  const hasEpisode = (episodeList?.length > 0)

  const hasNoResults = resultList?.length === 0

  return <div className="search-container">
    <h1>Rechercher</h1>
    <div className="search-form input-group">
      <input type="text" placeholder="Entrer une recherche" className="form-control" value={text} onChange={(e) => onChange(e.target.value)} onKeyPress={onKeyPress} />
      <span className="input-group-prepend">
        <button className="btn btn-dark" type="button" disabled={loading || !canSubmit}>{loading && <span className="icon-loading"> </span>} Rechercher</button>
      </span>
    </div>
    <div className="search-results">
      {hasNoResults && <div className="alert alert-dark">
        <h1>Pas de résultats :(</h1>
      </div>}
      {hasCollection && <div>
        <h1>Collection</h1>
        <MediaSlider elementList={collectionList} type={MediaTypeEnum.COLLECTION} />
      </div>}
      {hasMovie && <div>
        <h1>Movie</h1>
        <MediaSlider elementList={movieList} type={MediaTypeEnum.MOVIE} />
      </div>}
      {hasShow && <div>
        <h1>Série</h1>
        <MediaSlider elementList={showList} type={MediaTypeEnum.SHOW} />
      </div>}
      {hasEpisode && <div>
        <h1>Episode</h1>
        <MediaSlider elementList={episodeList} type={MediaTypeEnum.EPISODE} />
      </div>}
      {/* {episodeList?.map((el: any) => <h1>{el.id}</h1>)} */}
    </div>
  </div>
}