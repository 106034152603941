import gql from "graphql-tag";

import showListFragment from "../fragment/showListFragment"

export default gql`

	${showListFragment}

	query showList {
		showList {
			...showListFragment
		}
	}
`