import React from "react"
import {ImportEdit} from "components/backoffice/Transcode/Import/ImportEdit"

export const ImportEditRoute = (props: any) => {

  const {match} = props
  const {params} = match
  const {id} = params

  return <div>
    <ImportEdit id={id} />
  </div>
}