import gql from "graphql-tag"

export default gql`
	mutation episodeAdd(
		$name: String!
		$number: Int!
		$Season: String!
		$tmdbOptions: JSONObject!
	) {
		episodeAdd(
			input: {
				name: $name
				number: $number
				Season: $Season
				tmdbOptions: $tmdbOptions
			}
		) {
			id
			name
			tmdbOptions
		}
	}
`