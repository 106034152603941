import React from 'react'
import {useQuery} from '@apollo/react-hooks'

// import {VersionType} from "../../../../../../../common/model/Media/VersionType"

import movieGet from 'graphql/Media/Movie/query/movieGet'
import {MediaPlay} from '../../Media/Play/MediaPlay'

type MoviePlayProps = {
  id: string,
}

export const MoviePlay = (props: MoviePlayProps) => {

  // const [versionSelected, updateVersionSelected] = useState(null)

  const {data} = useQuery(movieGet, {
    // onCompleted: data => {
    //   // console.log({data})
    //   const versionList = data?.movie?.Media?.Version;
    //   const defaultVersion = versionList[0];
    //   updateVersionSelected(defaultVersion?.id);
    // },
    variables: {
      id: props?.id,
    }
  })

  const movie = data?.movie;
  const mediaId = movie?.Media?.id
  // const versionList: VersionType[] = movie?.Media?.Version;
  // const firstVersion = movie?.Media?.Version[0]

  // const hasManyVersions = versionList?.length > 1

  // const versionToPlay = hasManyVersions ? versionSelected : firstVersion

  return <div className="episode-play-container">
    <h1>{movie?.name}</h1>
    {/* {hasManyVersions && <div className="input-group">
      <select className="form-control" value={versionSelected} onChange={e => updateVersionSelected(e.target.value)}>
        {versionList?.map((version : VersionType) => {
          const {id, language} = version
          return <option value={id}>{language}</option>
        })}
      </select>
    </div>} */}
    <div>
      <MediaPlay id={mediaId} />
    </div>
  </div>
}