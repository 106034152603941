import React from "react"
import {UserDetails} from "../Details/UserDetails"

type UserListProps = {
  userList: any[]
}

export const UserList = (props: UserListProps) => {
  const {userList} = props

  return <div className="user-list-container">
    <h1>Liste des utilisateurs</h1>
    {userList?.map(user => {
      return <UserDetails userId={user?.id} />
    })}
  </div>
}