import gql from "graphql-tag";

export default gql`
  query userGet($id: String!) {
    user: userGet(id: $id) {
      id
      email
      isAdmin
    }
  }
`