import gql from "graphql-tag"

export default gql`
  fragment showListFragment on Show {
    id
    name
    tmdbOptions
    status
    Season {
      id
      number
      status
      Episode {
        id
        number
        name
        isViewable
      }
    }
  }
`